import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './Components/AuthContext';
import ScheduleView from './Components/Pages/ScheduleView';
import Home from './Components/Pages/Home';
import BookingPage from './Components/Pages/BookingPage';
import BookingConfirmation from './Components/Pages/BookingConfirmation';
import ClientIntegration from './Components/Pages/ClientIntegration';

function App() {

  return (
    <Router>
      <div className="App">
        <AuthProvider>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/book/:id' element={<BookingPage />} />
            <Route path='/schedule/:id' element={<ScheduleView />} />
            <Route path='/confirmation/:id' element={<BookingConfirmation />} />
            <Route path='/client' element={<ClientIntegration/>}/>
          </Routes>
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;