import {
    Avatar,
    Card,
    Container,
    Flex,
    Grid,
    SimpleGrid,
    Skeleton,
    Text,
    Title,
  } from "@mantine/core";
  import { useMediaQuery } from "@mui/material";
  import { format } from "date-fns";
  import React from "react";
  import { formatAMPM } from "../../Helper/TimeFormatting";
  
  function BookingTab({ bookingPageData }) {
    const isMobile = useMediaQuery("(max-width: 50em)");
  
  
    function getUserName(userId) {
      const user = bookingPageData.members.find(
        (user) => user.id === Number(userId)
      );
  
      return user ? user.name : "";
    }
  
    return (
      <Container miw={"100%"} mih={"100%"} pl={isMobile ? "0" : "80px"}>
        <Title
          p="xs"
          style={{ borderBottom: "solid 1px rgba(0,0,0,0.15)" }}
          order={2}
        >
          Booking Page
        </Title>
        <Grid mt={"sm"} w={"100%"} h={"100%"} style={{ overflow: "auto" }}>
          
        </Grid>
      </Container>
    );
  }
  
  export default BookingTab;
  