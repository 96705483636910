import {
  Avatar,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Group,
  SimpleGrid,
  Skeleton,
  Text,
  Title,
  Modal,
  Select,
  TextInput,
  ActionIcon,
  Menu,
  MantineProvider,
} from "@mantine/core";
import { useMediaQuery } from "@mui/material";
import {
  IconAt,
  IconCalendar,
  IconMail,
  IconMapPin,
  IconMessageCircle,
  IconPhone,
  IconPlus,
  IconUser,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, { useState } from "react";
import axios from "axios";
import { CiMenuKebab } from "react-icons/ci";
import { Notifications, notifications } from "@mantine/notifications";
import TipCycle from "../../TipCycle";

function Teams({
  bookingPageData,
  setBookingPageData,
  teamScheduleLinks,
  setTeamScheduleLinks,
}) {
  const [addMembersModalOpened, setAddMembersModalOpened] = useState(false);
  const [members, setMembers] = useState([]);
  const isMobile = useMediaQuery("(max-width: 50em)");

  const [email, setEmail] = useState("");
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

  const handleAddMember = () => {
    if (email) {
      if (emailRegex.test(email)) {
        if (!members.find((member) => member.email === email)) {
          setMembers([...members, { email, role: "Member" }]);
          setEmail("");
        } else {
          console.log("Email already present");
        }
      } else {
        console.log("Invalid email");
      }
    }
  };

  const handleRoleChange = (index, role) => {
    const newMembers = [...members];
    newMembers[index].role = role;
    setMembers(newMembers);
  };

  const handleRemoveMember = (index) => {
    const newMembers = [...members];
    newMembers.splice(index, 1);
    setMembers(newMembers);
  };

  const handleChangeRole = async (user, newRole) => {
    try {
      const response = await axios.post(
        `/api/changeUserRole`,
        { user, newRole },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          withCredentials: true,
        }
      );
      console.log("Role change success", response.data);

      setBookingPageData((prevData) => {
        const updatedMembers = prevData.members.map((member) => {
          if (member.id === user) {
            return { ...member, role: newRole };
          }
          return member;
        });
        return { ...prevData, members: updatedMembers };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveMemberFromData = (memberId) => {
    // Remove the member from the bookingPageData
    const updatedBookingPageData = {
      ...bookingPageData,
      members: bookingPageData.members.filter(
        (member) => member.id !== memberId
      ),
    };
    setBookingPageData(updatedBookingPageData);

    // Remove the member from each service link
    const updatedTeamScheduleLinks = teamScheduleLinks.map((link) => {
      // Remove the member from the members array
      const updatedMembers = link.members.filter((id) => id !== memberId);

      // Remove the member's availability
      const updatedAvailability = { ...link.availability };
      delete updatedAvailability[memberId];

      return {
        ...link,
        members: updatedMembers,
        availability: updatedAvailability,
      };
    });
    setTeamScheduleLinks(updatedTeamScheduleLinks);

    // Check and remove any members from the team links that are not in the bookingPageData.members
    const validMemberIds = new Set(
      updatedBookingPageData.members.map((member) => member.id)
    );
    const cleanedTeamScheduleLinks = updatedTeamScheduleLinks.map((link) => {
      // Filter the members array
      const cleanedMembers = link.members.filter((id) =>
        validMemberIds.has(id)
      );

      // Filter the availability object
      const cleanedAvailability = {};
      for (let id in link.availability) {
        if (validMemberIds.has(Number(id))) {
          cleanedAvailability[id] = link.availability[id];
        }
      }

      return {
        ...link,
        members: cleanedMembers,
        availability: cleanedAvailability,
      };
    });
    setTeamScheduleLinks(cleanedTeamScheduleLinks);
  };

  const handleRemoveUser = async (user) => {
    try {
      const response = await axios.post(
        `/api/removeUserFromEnterprise`,
        { user },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          withCredentials: true,
        }
      );
      handleRemoveMemberFromData(user);
      console.log("User", user);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container miw={"100%"} mih={"100%"} pl={isMobile ? "0" : "80px"}>
      <MantineProvider>
        <Notifications />
        <Flex
          w="100%"
          justify={"space-between"}
          align={"center"}
          style={{ borderBottom: "solid 1px rgba(0,0,0,0.15)" }}
          p="xs"
          mb={"10px"}
        >
          <Title order={2}>Team</Title>
          {bookingPageData.role !== "Member" && (
            <Button
            variant="outline"
              leftIcon={<IconPlus />}
              onClick={() => setAddMembersModalOpened(true)}
            >
              Add Members
            </Button>
          )}
        </Flex>
        {!isMobile && <TipCycle currentPage="team" />}
        <Flex
          align={"flex-start"}
          mt={"sm"}
          w={"100%"}
          h={"100%"}
          style={{
            alignContent: "flex-start",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          wrap="wrap"
        >
          {bookingPageData.members.map((member) => {
            let initials = `${member.name[0]}`;
            if (member.name.includes(" ")) {
              let nameSplit = member.name.split(" ");
              initials = `${nameSplit[0][0]}${nameSplit[1][0]}`;
            }
            return (
              <Card
                withBorder
                p={"1em"}
                mt={"1em"}
                ml={isMobile ? "0em" : "1em"}
                w={"100%"}
                maw={"350px"}
                miw={"275px"}
                mah={"125px"}
                shadow="xs"
                style={{ position: "relative", overflow: "visible" }}
              >
                <Menu shadow="md" withArrow maw={250} position="bottom">
                  <Menu.Target>
                    <ActionIcon
                      size="lg"
                      style={{ position: "absolute", top: "1em", right: "1em" }}
                    >
                      <CiMenuKebab size={20} />
                    </ActionIcon>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Label>Member Info</Menu.Label>
                    <Menu.Item size="sm">
                      <b>Email:</b> {member.email}
                    </Menu.Item>
                    <Menu.Item size="sm">
                      <b>Name:</b> {member.name}
                    </Menu.Item>
                    <Menu.Item size="sm">
                      <b>Role:</b> {member.role}
                    </Menu.Item>
                    <Menu.Divider />

                    {bookingPageData.role === "Owner" &&
                      bookingPageData.id !== member.id &&
                      member.role !== "Owner" && (
                        <Menu.Item
                          icon={<IconUser size={20} />}
                          onClick={() =>
                            handleChangeRole(
                              member.id,
                              member.role === "Admin" ? "Member" : "Admin"
                            )
                          }
                        >
                          Change to{" "}
                          {member.role === "Admin" ? "Member" : "Admin"}
                        </Menu.Item>
                      )}

                    {(bookingPageData.role === "Owner" ||
                      (bookingPageData.role === "Admin" &&
                        member.role === "Member")) &&
                      member.role !== "Owner" && (
                        <>
                          <Menu.Item
                            color="red"
                            icon={<IconTrash size={20} />}
                            onClick={() => handleRemoveUser(member.id)}
                          >
                            Remove User
                          </Menu.Item>
                        </>
                      )}
                  </Menu.Dropdown>
                </Menu>
                <Group noWrap>
                  <Avatar
                    size={94}
                    radius="md"
                    color={`${bookingPageData[0].color_theme}`}
                    src={member?.profile_image}
                  >
                    {initials}
                  </Avatar>
                  <div>
                    <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                      {member.role}
                    </Text>

                    <Text fz="lg" fw={500}>
                      {member.name}
                    </Text>

                    <Group noWrap spacing={10} mt={3}>
                      <IconAt stroke={1.5} size="1rem" />
                      <Text fz="xs" c="dimmed">
                        {member.email}
                      </Text>
                    </Group>
                  </div>
                </Group>
              </Card>
            );
          })}
        </Flex>
      </MantineProvider>
      <Modal
        title="Add Members"
        opened={addMembersModalOpened}
        onClose={() => setAddMembersModalOpened(false)}
        size={"35em"}
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();

            if (members.size == 0) {
              console.log("Form invalid");
              return;
            } else {
              axios
                .post(
                  `/api/addEnterpriseMembers/${bookingPageData[0].id}`,
                  { members },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    withCredentials: true,
                  }
                )
                .then((response) => {
                  console.log("Success", response.data);
                  setBookingPageData((prevState) => ({
                    ...prevState,
                    members: [
                      ...prevState.members,
                      ...response.data.successfulAccounts,
                    ],
                  }));
                  if (response.data.failedAccounts.length > 0) {
                    notifications.show({
                      title: "Failed to add these accounts",
                      message: response.data.failedAccounts
                        .map((email) => `${email}`)
                        .join(", "),
                      color: "red",
                    });
                  }
                  setAddMembersModalOpened(false);
                  setMembers([]);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }}
        >
          <Title order={4}>Members</Title>
          <Text color={"rgba(0,0,0,0.5)"}>
            Input emails of members to add below.
          </Text>
          <Text color={"dimmed"} fz={"sm"} italic>
            Make sure the user has an account already and hasn't already been
            added.
          </Text>
          <div
            style={{
              width: "100%",
              marginTop: "1em",
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            {members.map((member, index) => (
              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "solid 1px rgba(0,0,0,0.15)",
                  borderRadius: "5px",
                  padding: "0.5em 0.75em",
                }}
                key={index}
              >
                <Text truncate>{member.email}</Text>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1em" }}
                >
                  {member.role === "Owner" ? (
                    <Select disabled data={["Owner"]} value={"Owner"} />
                  ) : (
                    <Select
                      data={["Admin", "Member"]}
                      value={member.role}
                      onChange={(role) => handleRoleChange(index, role)}
                      miw={"100px"}
                    />
                  )}
                  {member.role !== "Owner" && (
                    <ActionIcon onClick={() => handleRemoveMember(index)}>
                      <IconX />
                    </ActionIcon>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              gap: "1em",
              marginTop: "2em",
            }}
          >
            <TextInput
              label="Enter user's email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.currentTarget.value)}
              w={"250px"}
              miw={"175px"}
            />
            <Button variant="outline" onClick={handleAddMember}>Add Member</Button>
          </div>
          <Button variant="outline" type="submit" mt="2em">
            Add Members
          </Button>
        </form>
      </Modal>
    </Container>
  );
}

export default Teams;
