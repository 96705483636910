import {
  Avatar,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Group,
  SimpleGrid,
  Skeleton,
  Text,
  Title,
  Modal,
  Select,
  TextInput,
  ActionIcon,
  Menu,
  Center,
  Textarea,
  Divider,
  Badge,
  Input,
  FileInput,
} from "@mantine/core";
import { useMediaQuery } from "@mui/material";
import {
  IconAt,
  IconCalendar,
  IconMail,
  IconMapPin,
  IconMessageCircle,
  IconPhone,
  IconPlus,
  IconUser,
  IconTrash,
  IconX,
  IconAppWindow,
  IconEdit,
} from "@tabler/icons-react";
import React, { useState } from "react";
import axios from "axios";
import { CiMenuKebab } from "react-icons/ci";
import { Form, useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import TipCycle from "../../TipCycle";

function BusinessInfo({
  bookingPageData,
  teamScheduleLinks,
  setBookingPageData,
}) {
  const isMobile = useMediaQuery("(max-width: 50em)");
  let initials = `${bookingPageData[0].name[0]}`;
  if (bookingPageData[0].name.includes(" ")) {
    let nameSplit = bookingPageData[0].name.split(" ");
    initials = `${nameSplit[0][0]}${nameSplit[1][0]}`;
  }
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const navigate = useNavigate();

  const [imagePlacehold, setImagePlacehold] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleImageChange = (file) => {
    if (!file) return;

    console.log(file);

    const fileUrl = URL.createObjectURL(file);
    setImagePlacehold(fileUrl);
    setImageFile(file);
  };

  const [editInfoModalOpen, setEditInfoModalOpen] = useState(false);

  const editBusinessInfo = useForm({
    initialValues: {
      description: bookingPageData[0].description,
      location: bookingPageData[0].location,
      website: bookingPageData[0].website,
    },
    validate: {
      website: (value) => {
        if (value.length > 0) {
          if (!urlRegex.test(value)) {
            return "Please enter a valid URL, Ex: www.bluemail.me";
          }
        }
        return null;
      },
    },
  });

  return (
    <Container miw={"100%"} mih={"100%"} pl={isMobile ? "0" : "80px"}>
      <Flex
        w="100%"
        justify={"space-between"}
        align={"center"}
        style={{ borderBottom: "solid 1px rgba(0,0,0,0.15)" }}
        p="xs"
      >
        <Title order={2}>Business Info</Title>
        {bookingPageData.role !== "Member" && (
          <Button
          variant="outline"
            leftIcon={<IconEdit />}
            onClick={() => setEditInfoModalOpen(true)}
          >
            Edit Info
          </Button>
        )}
      </Flex>
      {!isMobile &&
      <TipCycle currentPage="businessInfo" />}
      <Flex
        align={"flex-start"}
        mt={"sm"}
        w={"100%"}
        h={"100%"}
        style={{
          alignContent: "flex-start",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        wrap="wrap"
      >
        <SimpleGrid
          cols={2}
          spacing="md"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          h={"98%"}
          w={"100%"}
        >
          <Card
            h={"100%"}
            radius="md"
            style={{ overflowY: "auto", overflowX: "hidden" }}
          >
            <Flex gap={"md"}>
              <Avatar
                style={{ border: "solid 1px rgba(0,0,0,0.5)" }}
                size={120}
                color={`${bookingPageData[0]?.color_theme}`}
                src={bookingPageData[0] ? bookingPageData.logo_url : ""}
              >
                {initials}
              </Avatar>
              <Flex direction={"column"} justify={"space-evenly"}>
                <Title>{bookingPageData[0].name}</Title>
                {!isMobile && (
                  <>
                    {bookingPageData[0].location && (
                      <Flex gap={"md"}>
                        <IconMapPin />
                        <Text color="dimmed">
                          {bookingPageData[0].location}
                        </Text>
                      </Flex>
                    )}
                    {bookingPageData[0].website && (
                      <Flex gap={"md"}>
                        <IconAppWindow />
                        <Text color="dimmed">{bookingPageData[0].website}</Text>
                      </Flex>
                    )}
                  </>
                )}
              </Flex>
            </Flex>
            {isMobile && (
              <Flex direction={"column"} mt="lg">
                {bookingPageData[0].location && (
                  <Flex gap={"md"}>
                    <IconMapPin />
                    <Text color="dimmed">{bookingPageData[0].location}</Text>
                  </Flex>
                )}
                {bookingPageData[0].website && (
                  <Flex gap={"md"}>
                    <IconAppWindow />
                    <Text color="dimmed">{bookingPageData[0].website}</Text>
                  </Flex>
                )}
              </Flex>
            )}
            <Text mt="lg">{bookingPageData[0].description}</Text>
          </Card>
          <Grid gutter="sm">
            <Grid.Col>
              <Card h={"100%"} radius="md">
                {teamScheduleLinks.length > 0 && (
                  <>
                    <Title order={3}>Services</Title>
                    <Flex
                      align={"flex-start"}
                      mt={"sm"}
                      w={"100%"}
                      h={"100%"}
                      style={{
                        alignContent: "flex-start",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                      wrap="wrap"
                      gap={"sm"}
                    >
                      {teamScheduleLinks.map((link, index) => {
                        return (
                          <Badge
                            key={index}
                            p={"md"}
                            fz="sm"
                            style={{ cursor: "pointer" }}
                            color={`${bookingPageData[0]?.color_theme}`}
                            onClick={() => {
                              navigate(`/schedule/${link.id}`);
                            }}
                          >
                            {link.name}
                          </Badge>
                        );
                      })}
                    </Flex>
                  </>
                )}
              </Card>
            </Grid.Col>
            {/* <Grid.Col >
              <Card h={"100%"} radius="md" withBorder>
              <Title order={3}>Links</Title>
                <Flex align={'flex-start'}  mt={"sm"} w={"100%"} h={"100%"} style={{alignContent: 'flex-start', overflowY: "scroll", overflowX: "hidden" }} wrap="wrap">
                    {teamScheduleLinks.map((link, index) => {
                        return (
                            <Badge key={index} p={'md'} fz='sm' color={`${bookingPageData[0].color_theme}`}>
                                {link.name}
                            </Badge>
                        )
                    })}
                </Flex>
              </Card>
            </Grid.Col> */}
          </Grid>
        </SimpleGrid>
      </Flex>
      <Modal
        title="Edit Business Info"
        opened={editInfoModalOpen}
        onClose={() => setEditInfoModalOpen(false)}
        size={"50em"}
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const isFormValid = editBusinessInfo.validate();

            if (isFormValid.hasErrors) {
              console.log("Form invalid");
              return;
            } else {
              const formData = new FormData();
              formData.append("image", imageFile);
              formData.append("name", bookingPageData[0].name);
              Object.keys(editBusinessInfo.values).forEach((key) => {
                formData.append(key, editBusinessInfo.values[key]);
              });

              // First, upload the image
              axios
                .post("/api/imageUpload", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  withCredentials: true,
                })
                .then((uploadResponse) => {
                  const updatedValues = {
                    ...editBusinessInfo.values,
                    logo_url: uploadResponse.data.imageUrl,
                  };

                  return axios.put(
                    `/api/editBusinessInfo/${bookingPageData[0].id}`,
                    updatedValues,
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                      withCredentials: true,
                    }
                  );
                })
                .then((updateResponse) => {
                  // Update local state with new business info
                  console.log(updateResponse.data);
                  setBookingPageData((prevData) => ({
                    ...prevData,
                    0: {
                      ...prevData[0],
                      logo_url: imagePlacehold,
                    },
                    logo_url: imagePlacehold,
                  }));
                  setEditInfoModalOpen(false);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }}
        >
          <Title order={4}>Business Info</Title>
          <Flex mt={"md"} w={"100%"} align={"flex-end"} gap={"1em"}>
            <Avatar
              w={"5em"}
              h={"5em"}
              src={imagePlacehold ? imagePlacehold : bookingPageData.logo_url}
            />
            <TextInput
              disabled
              label={"Name"}
              value={bookingPageData[0].name}
              mt="1em"
              w={"calc(100% - 5em)"}
            />
          </Flex>
          <Flex gap="md" mt="1em">
            <TextInput
              label={"Location"}
              icon={<IconMapPin />}
              w={"50%"}
              {...editBusinessInfo.getInputProps("location")}
            />
            <TextInput
              label={"Website"}
              icon={<IconAppWindow />}
              w={"50%"}
              {...editBusinessInfo.getInputProps("website")}
            />
          </Flex>
          <FileInput
            label="Change Business Logo"
            onChange={handleImageChange}
            accept="image/*"
            mt="1em"
          />
          <Textarea
            label={"Description"}
            mt={"1em"}
            {...editBusinessInfo.getInputProps("description")}
          />
          <Button variant="outline" type="submit" mt="3em" mb="4em">
            Save Changes
          </Button>
        </form>
      </Modal>
    </Container>
  );
}

export default BusinessInfo;