import {
  Avatar,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Group,
  SimpleGrid,
  Skeleton,
  Text,
  Title,
  Modal,
  Select,
  TextInput,
  ActionIcon,
  Menu,
} from "@mantine/core";
import { useMediaQuery } from "@mui/material";
import {
  IconAt,
  IconCalendar,
  IconMail,
  IconMapPin,
  IconMessageCircle,
  IconPhone,
  IconPlus,
  IconUser,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, { useState } from "react";
import axios from "axios";
import { CiMenuKebab } from "react-icons/ci";
import TipCycle from "../../TipCycle";

function Customers({ agendaData, bookingPageData }) {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const customerData = agendaData.filter(
    (v, i, a) => a.findIndex((t) => t.email === v.email) === i
  );

  return (
    <Container miw={"100%"} mih={"100%"} pl={isMobile ? "0" : "80px"}>
      <Flex
        w="100%"
        justify={"space-between"}
        align={"center"}
        style={{ borderBottom: "solid 1px rgba(0,0,0,0.15)" }}
        p="xs"
      >
        <Title order={2}>Customers</Title>
      </Flex>
      {!isMobile &&
      <TipCycle currentPage="customers" />}
      <Flex
        align={"flex-start"}
        mt={"sm"}
        w={"100%"}
        h={"100%"}
        style={{
          alignContent: "flex-start",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        wrap="wrap"
      >
        {customerData && customerData.length > 0 ? (
          customerData.map((customer, index) => {
            let initials = `${customer.name[0]}`;
            if (customer.name.includes(" ")) {
              let nameSplit = customer.name.split(" ");
              initials = `${nameSplit[0][0]}${nameSplit[1][0]}`;
            }
            return (
              <Card
                withBorder
                p={"1em"}
                mt={"1em"}
                ml={isMobile ? "0em" : "1em"}
                w={"100%"}
                maw={"350px"}
                miw={"275px"}
                mah={"125px"}
                shadow="xs"
                style={{ position: "relative", overflow: "visible" }}
                key={index}
              >
                <Group noWrap>
                  <Avatar
                    size={94}
                    radius="md"
                    color={`${bookingPageData[0]?.color_theme}`}
                  >
                    <Title>{initials}</Title>
                  </Avatar>
                  <div>
                    <Text fz="lg" fw={500}>
                      {customer.name}
                    </Text>

                    <Group noWrap spacing={10} mt={3}>
                      <IconAt stroke={1.5} size="1rem" />
                      <Text fz="xs" c="dimmed">
                        {customer.email}
                      </Text>
                    </Group>
                  </div>
                </Group>
              </Card>
            );
          })
        ) : (
          <Flex direction={"column"} mt={'10px'} gap="0.25em">
            <Title order={5}>
              There are no upcoming meetings with any customers.
            </Title>
            <Title order={6} color="gray">
              To see which customers you have meetings with, please share your schedule links with
              others!
            </Title>
          </Flex>
        )}
      </Flex>
    </Container>
  );
}

export default Customers;
