import { useForm } from "@mantine/form";
import {
  Checkbox,
  Modal,
  Title,
  Button,
  Grid,
  Collapse,
  Select,
  ActionIcon,
  Text,
  TextInput,
  Textarea,
  Divider,
  Tooltip,
  Header,
  Image,
  Tabs,
  Paper,
  Col,
  useMantineTheme,
  ColorSwatch,
  Group,
  FileInput,
  Avatar,
  Navbar,
  Burger,
  Flex,
} from "@mantine/core";
import {
  IconTrash,
  IconBrandGoogle,
  IconSettings,
  IconUpload,
  IconBrandOffice,
} from "@tabler/icons-react";
import { AuthContext } from "../AuthContext";
import React, { useEffect, useState, useContext } from "react";
import "../../Styles/CreateMeeting.css";
import BlueMail_Logo from "../../Images/BlueMail-App-icon.png";
import axios from "axios";
import "../../Styles/Dashboard.css";
import { useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { debounce } from "lodash";

function HeaderMenu({ setBookingPages, setNavbarVisible, isNavbarVisible }) {
  // Signup/Login Modals

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoginClicked, setIsLoginClicked] = useState(false);

  // Settings Modal

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [image, setImage] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [error, setError] = useState(""); // To display errors to the user

  // Auth Context

  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const isMobile = useMediaQuery("(max-width: 50em)");
  const location = useLocation();
  const isBookingPage = location.pathname.includes("/book/");
  const [opened, { toggle }] = useDisclosure(false);

  const navigate = useNavigate();
  const linkingStatus = new URLSearchParams(location.search).get(
    "linkingStatus"
  );
  const debouncedAlert = debounce((message) => alert(message), 100);

  useEffect(() => {
    if (linkingStatus) {
      const message =
        linkingStatus === "alreadyLinked"
          ? "An account with that email is already linked."
          : "Account successfully linked.";

      debouncedAlert(message);

      // Clear linkingStatus from the URL after showing the alert
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("linkingStatus");
      window.history.replaceState({}, "", newUrl.href);
    }
  }, [linkingStatus]);

  useEffect(() => {
    axios
      .get("/api/getLinkedAccounts", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        withCredentials: true,
      })
      .then((res) => {
        setLinkedAccounts(res.data[0]);
      })
      .catch((err) => console.error(err));

    const fetchUserProfile = async () => {
      try {
        const response = await axios.get("/api/getUserProfile", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          withCredentials: true,
        });
        setIsCheckboxChecked(response.data.auto_calendar_creation === 1);
        setImage(response.data.profile_image);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [isLoggedIn]);

  const handleFileChange = async (file) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post("/api/imageUpload", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        withCredentials: true,
      });

      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.error("Error uploading the image:", error);
    }
  };

  const handleLinkGoogleAccount = () => {
    const token = localStorage.getItem("token");
    const currentUrl = encodeURIComponent(window.location.href);
    window.location.href = `https://sch.ultrai.me/api/login/google?token=${token}&redirectUrl=${currentUrl}&prompt=select_account`;
  };

  const handleLinkOutlookAccount = () => {
    const token = localStorage.getItem("token");
    const currentUrl = encodeURIComponent(window.location.href);
    window.location.href = `https://sch.ultrai.me/api/login/microsoft?token=${token}&redirectUrl=${currentUrl}`;
  };

  const handleUnlinkGoogleAccount = (accountId) => {
    if (
      window.confirm(
        "Are you sure you want to unlink this email?\nYou can connect it again in the future."
      )
    ) {
      axios
        .delete(`/api/removeLinkedAccount/${accountId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          withCredentials: true,
        })
        .then(() => {
          const updatedAccounts = linkedAccounts.filter(
            (account) => account.id !== accountId
          );
          setLinkedAccounts(updatedAccounts);
        })
        .catch((err) => console.error(err));
    }
  };

  async function toggleCalendarCreation() {
    let newSetting = !isCheckboxChecked;
    setIsButtonDisabled(true);
    try {
      const response = await axios.patch(
        "/api/user/updateAutomaticCalendar",
        {
          autoCalendarCreation: newSetting ? 1 : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          withCredentials: true,
        }
      );

      if (response.data.message === "Setting updated successfully") {
        setIsCheckboxChecked(newSetting);
        if (newSetting === true) {
          await axios.post(
            "/api/createServiceCalendars",
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              withCredentials: true,
            }
          );
        } else {
          await axios.post(
            "/api/deleteServiceCalendars",
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              withCredentials: true,
            }
          );
        }
      }
    } catch (error) {
      console.error("Error updating setting:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.suggestReconnect
      ) {
        setCalendarError(
          "There was an error with your Google integration. Please try reconnecting your Google account."
        );
      } else {
        setCalendarError("An unexpected error occurred. Please try again.");
      }
      setIsCheckboxChecked(!newSetting);
    } finally {
      setIsButtonDisabled(false);
    }
  }

  // Form states
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [calendarError, setCalendarError] = useState("");

  const handleModalClose = () => {
    setIsLoginModalOpen(false);
    setIsSignUp(false);
    setEmail("");
    setFullName("");
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (isSignUp) {
      if (fullName.length < 3 || !fullName.match(/^[A-Za-z\s]+$/)) {
        setError(
          "Full name must be at least 3 characters long and contain only letters"
        );
        setLoading(false);
        return;
      }
    }

    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setError("Invalid email");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        isSignUp ? "/api/signup" : "/api/login",
        {
          email,
          fullName, // Note: Ensure your backend is prepared to optionally accept fullName for signup only
        },
        {
          withCredentials: true,
        }
      );

      console.log("Success", response.data);
      setIsLoginModalOpen(false);
      setIsLoggedIn(true);
      localStorage.setItem("token", response.data.token);
      setError("");
    } catch (error) {
      console.error(error);
      setError(error.response.data.message);
    } finally {
      setLoading(false);
      setEmail("");
      setFullName("");
    }
  };

  return (
    <>
      <Header
        height={56}
        mb={120}
        style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}
        background={"#134fb4"}
      >
        <div className="Meeting-Header">
          <div className="Meeting-Header-Logo" style={{ cursor: "pointer" }}>
            <Image
              width={40}
              src={BlueMail_Logo}
              onClick={() => navigate("/")}
              style={{ objectFit: "contain" }}
            />
            <Title
              style={{ userSelect: "none" }}
              onClick={() => navigate("/")}
              order={2}
              color={"#134fb4"}
              id="BlueMail-Title"
            >
              BlueMail Scheduler
            </Title>
          </div>
          {isLoggedIn ? (
            <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
              <Tooltip label="Settings" position="top">
                <ActionIcon
                  size="lg"
                  onClick={() => setIsSettingsModalOpen(true)}
                >
                  <IconSettings />
                </ActionIcon>
              </Tooltip>

              <Button
                variant={"outline"}
                onClick={() => {
                  setIsLoggedIn(false);
                  navigate("/");
                  localStorage.removeItem("token");
                  localStorage.setItem("isLoggedIn", "false");
                  setImage(null);
                  setIsSignUp(false);
                  {
                    setBookingPages && setBookingPages([]);
                  }
                }}
              >
                Logout
              </Button>

              {isMobile && isBookingPage && (
                <Burger
                  opened={opened}
                  onClick={() => {
                    setNavbarVisible(!isNavbarVisible);
                    toggle();
                  }}
                />
              )}
            </div>
          ) : (
            <Button variant="outline" onClick={() => setIsLoginModalOpen(true)}>
              Login
            </Button>
          )}
        </div>
      </Header>
      <Modal
        title={isSignUp ? "Sign Up" : "Login"}
        opened={isLoginModalOpen}
        onClose={handleModalClose}
        centered
      >
        <form onSubmit={handleSubmit}>
          {isSignUp && (
            <TextInput
              label="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="mb-4"
            />
          )}
          <TextInput
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mb-4"
          />
          <Text className="mt-8 text-sm text-red-600 font-light mb-2">
            {error}
          </Text>
          <Button
            className="w-full mb-4"
            variant="outline"
            type="submit"
            loading={loading}
          >
            {isSignUp ? "Sign Up" : "Log In"}
          </Button>
        </form>
        <Button
          className="cursor-pointer font-medium hover:underline hover:underline-offset-4 w-auto text-blue-500"
          onClick={() => {
            setIsSignUp(!isSignUp);
            setError("");
          }}
          disabled={loading}
          variant="unstyled"
        >
          {isSignUp ? "Already a user? Login" : "Not a user? Sign up"}
        </Button>
      </Modal>
      <Modal
        title="Settings"
        // fullScreen={isMobile}
        opened={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        transitionProps={{ transition: "fade", duration: 200 }}
        centered
        size="md"
      >
        <Paper padding="md" pb="2em">
          <Grid gutter="md" mb="1em">
            <Col span={12}>
              <Text size="xl" weight={500}>
                Linked Accounts
              </Text>
              <Divider mt="0.5em" mb="0.75em" />
              <div className="flex flex-col w-full mb-4">
                {linkedAccounts &&
                  linkedAccounts.map((account) => {
                    return (
                      <Grid
                        gutter="md"
                        justify="between"
                        gap="1em"
                        align="center"
                      >
                        <Col span={1}>
                          {account.provider == "Google" ? (
                            <IconBrandGoogle />
                          ) : (
                            <IconBrandOffice />
                          )}
                        </Col>
                        <Col span={9}>
                          <Text>{account.email}</Text>
                        </Col>
                        <Col span={2}>
                          <Button
                            variant={"subtle"}
                            color="red"
                            onClick={() =>
                              handleUnlinkGoogleAccount(account.id)
                            }
                          >
                            <IconTrash />
                          </Button>
                        </Col>
                      </Grid>
                    );
                  })}
              </div>
              <Flex align={"center"} justify={"flex-start"} gap={"md"}>
                <Button
                  onClick={handleLinkGoogleAccount}
                  leftIcon={<IconBrandGoogle />}
                  variant={"outline"}
                  color={"green"}
                >
                  Link Gmail
                </Button>
                {/* <Button
                  onClick={handleLinkOutlookAccount}
                  leftIcon={<IconBrandOffice />}
                  variant={"outline"}
                  color={"blue"}
                >
                  Link Outlook
                </Button> */}
              </Flex>
            </Col>
          </Grid>

          {linkedAccounts &&
            linkedAccounts.find((account) => account.provider === "Google") && (
              <div className="flex flex-col justify-between">
                <Text size="xl" weight={500}>
                  Integrations
                </Text>
                <Divider mt="0.5em" mb="0.75em" />
                <div class="flex items-center justify-start">
                  <label for="toggle" class="flex items-center cursor-pointer">
                    <div class="relative">
                      <input
                        id="toggle"
                        type="checkbox"
                        className="sr-only peer"
                        onChange={toggleCalendarCreation}
                        checked={isCheckboxChecked}
                        disabled={isButtonDisabled}
                      />
                      <div class="w-12 h-7 bg-gray-500 rounded-full shadow-inner peer-checked:bg-blue-700"></div>
                      <div class="absolute left-1 top-1 bg-white w-5 h-5 rounded-full transition peer-checked:transform peer-checked:translate-x-5"></div>
                    </div>
                    <div class="ml-3 text-md font-medium text-gray-900">
                      Automatic Calendar Creation
                    </div>
                  </label>
                </div>
                { (
                  <div className="text-red-500 text-sm font-light mt-4">{calendarError}</div>
                )}
              </div>
            )}

          <Grid gutter="md" mt="1em">
            <Col span={12}>
              <Text size="xl" weight={500}>
                Profile Image
              </Text>
              <Divider mt="0.5em" mb="0.75em" />
            </Col>
          </Grid>
          <Grid
            gutter="md"
            pt="0.75em"
            style={{
              alignItems: "center",
              justifyContent: "space-evenly",
              display: "flex",
            }}
            mb="0.5em"
          >
            <Avatar
              src={image}
              size={"6em"}
              radius={50}
              color="#134fb4"
              alt="User Image"
            />
            <FileInput
              multiple={false}
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleFileChange}
              label="Upload Profile Image"
              fullWidth
              icon={<IconUpload />}
              miw={"55%"}
              maw={"55%"}
            />
          </Grid>
          <Grid gutter="md" mt="1em">
            <Col span={12}>
              <Text size="xl" weight={500}>
                Version
              </Text>
              <Divider mt="0.5em" mb="0.75em" />
              <Flex w={"100%"} justify={"end"}>
                <Text>Version 1.75</Text>
              </Flex>
            </Col>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}

export default HeaderMenu;
