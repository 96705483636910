import {
  BackgroundImage,
  Button,
  Card,
  Center,
  Container,
  Divider,
  Flex,
  Group,
  Image,
  Skeleton,
  Text,
  Title,
} from "@mantine/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  IconCalendar,
  IconMapPin,
  IconUser,
  IconClock,
  IconInfoCircle,
} from "@tabler/icons-react";
import ConfirmationBackground from "../../Images/ConfirmationBackground.svg";
import BlueMailLogo from "../../Images/BlueMail-App-icon.png";
import NoData from "../../Images/no_data.svg";
import { formatAMPM } from "../Helper/TimeFormatting";
import moment from "moment";

function BookingConfirmation() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [eventData, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/getEvent/${id}`)
      .then((res) => {
        setEventData(res.data);
        console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsError(true);
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return (
      <Container miw={"100vw"} w={"100vw"} mah={"100vh"} h={"100vh"} p={"0"}>
        <BackgroundImage
          miw={"100vw"}
          w={"100vw"}
          mah={"100vh"}
          h={"100vh"}
          p={"0"}
          src={ConfirmationBackground}
        >
          <Center miw={"100%"} mih={"100%"}>
            <Card
              miw={"370px"}
              w={"100%"}
              maw={"500px"}
              mih={"550px"}
              h={"50%"}
              withBorder
              shadow={"md"}
            >
              <Flex
                miw={"100%"}
                w={"100%"}
                gap={"md"}
                align={"center"}
                justify={"flex-start"}
              >
                <Image maw={55} mx="auto" src={BlueMailLogo} />
                <Flex
                  w={"100%"}
                  direction={"column"}
                  align={"flex-start"}
                  justify={"flex-start"}
                >
                  <Skeleton w={"100%"} h={"60px"} animate={true} />
                </Flex>
              </Flex>
              <Divider mt={"md"} mb="md" />
              <Group mt={"md"}>
                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                  <Skeleton w={"100%"} h={"40px"} animate={true} />
                </Flex>
                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                  <Skeleton w={"100%"} h={"40px"} animate={true} />
                </Flex>
                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                  <Skeleton w={"100%"} h={"40px"} animate={true} />
                </Flex>
                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                  <Skeleton w={"100%"} h={"40px"} animate={true} />
                </Flex>
              </Group>
              <Divider mt={"xs"} mb="md" />
              <Group mt={"md"}>
                <Flex
                  mt={0}
                  miw={"100%"}
                  h={"100%"}
                  p={"md"}
                  direction={"column"}
                  gap={"md"}
                >
                  <Skeleton
                    h={"45px"}
                    fz={"md"}
                    p={"sm"}
                    animate={"true"}
                  ></Skeleton>
                  <Skeleton
                    h={"45px"}
                    fz={"md"}
                    p={"sm"}
                    animate={"true"}
                  ></Skeleton>
                  <Skeleton
                    h={"45px"}
                    fz={"md"}
                    p={"sm"}
                    animate={"true"}
                  ></Skeleton>
                </Flex>
              </Group>
            </Card>
          </Center>
        </BackgroundImage>
      </Container>
    );
  } else if (isError) {
    return (
      <Container miw={"100vw"} w={"100vw"} mah={"100vh"} h={"100vh"} p={"0"}>
        <BackgroundImage
          miw={"100vw"}
          w={"100vw"}
          mah={"100vh"}
          h={"100vh"}
          p={"0"}
          src={ConfirmationBackground}
        >
          <Center miw={"100%"} mih={"100%"}>
            <Card
              miw={"370px"}
              w={"100%"}
              maw={"500px"}
              mih={"450px"}
              h={"50%"}
              withBorder
              shadow={"md"}
            >
              <Flex
                direction="column"
                miw={"370px"}
                w={"100%"}
                maw={"500px"}
                mih={"450px"}
                h={"50%"}
                align={"center"}
                justify={"center"}
                gap={"md"}
              >
                <Image
                  src={NoData}
                  maw={275}
                  mx="auto"
                  radius="md"
                />
                <Title order={2} mt={'md'}>No booking found</Title>
              </Flex>
            </Card>
          </Center>
        </BackgroundImage>
      </Container>
    );
  } else if (isDeleted) {
    return (
      <Container miw={"100vw"} w={"100vw"} mah={"100vh"} h={"100vh"} p={"0"}>
        <BackgroundImage
          miw={"100vw"}
          w={"100vw"}
          mah={"100vh"}
          h={"100vh"}
          p={"0"}
          src={ConfirmationBackground}
        >
          <Center miw={"100%"} mih={"100%"}>
            <Card
              miw={"370px"}
              w={"100%"}
              maw={"500px"}
              mih={"300px"}
              h={"50%"}
              withBorder
              shadow={"md"}
            >
              <Flex
                miw={"100%"}
                w={"100%"}
                gap={"md"}
                align={"center"}
                justify={"flex-start"}
              >
                <Image maw={55} mx="auto" src={BlueMailLogo} />
                <Flex
                  w={"100%"}
                  direction={"column"}
                  align={"flex-start"}
                  justify={"flex-start"}
                >
                  <Title order={2}>Booking Cancellation</Title>
                  <Title order={3} color={"dimmed"}>
                    {eventData.enterprise.name} -{" "}
                    {eventData.enterpriseLink.name}
                  </Title>
                </Flex>
              </Flex>
              <Divider mt={"md"} mb="md" />
              <Text mt={"md"} fw={600}>
                Your booking has been successfully cancelled.
              </Text>
              <Group mt={"md"}>
                <Flex
                  mt={0}
                  miw={"100%"}
                  h={"100%"}
                  p={"md"}
                  direction={"column"}
                  gap={"md"}
                >
                  <Button
                    h={"45px"}
                    fz={"md"}
                    p={"sm"}
                    variant="outline"
                    onClick={() => {
                      navigate(`/schedule/${eventData.enterpriseLink.id}`);
                    }}
                  >
                    New Booking
                  </Button>
                  <Flex
                    mt={"md"}
                    miw={"100%"}
                    gap="sm"
                    align={"center"}
                    justify={"center"}
                  >
                    <IconInfoCircle size={15} />
                    <Text fz={"sm"} color={"dimmed"} italic>
                      All times are in{" "}
                      {eventData.event.timezone.replace("_", " ")} timezone
                    </Text>
                  </Flex>
                </Flex>
              </Group>
            </Card>
          </Center>
        </BackgroundImage>
      </Container>
    );
  } else {
    return (
      <Container miw={"100vw"} w={"100vw"} mah={"100vh"} h={"100vh"} p={"0"}>
        <BackgroundImage
          miw={"100vw"}
          w={"100vw"}
          mah={"100vh"}
          h={"100vh"}
          p={"0"}
          src={ConfirmationBackground}
        >
          <Center miw={"100%"} mih={"100%"}>
            <Card
              miw={"370px"}
              w={"100%"}
              maw={"500px"}
              mih={"650px"}
              h={"50%"}
              withBorder
              shadow={"md"}
            >
              <Flex
                miw={"100%"}
                w={"100%"}
                gap={"md"}
                align={"center"}
                justify={"flex-start"}
              >
                <Image maw={55} mx="auto" src={BlueMailLogo} />
                <Flex
                  w={"100%"}
                  direction={"column"}
                  align={"flex-start"}
                  justify={"flex-start"}
                >
                  <Title order={2}>Booking Confirmation</Title>
                  <Title order={3} color={"dimmed"}>
                    {eventData.enterprise.name} -{" "}
                    {eventData.enterpriseLink.name}
                  </Title>
                </Flex>
              </Flex>
              <Divider mt={"md"} mb="md" />
              <Title order={4}>Appointment Details</Title>
              <Group mt={"md"}>
                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                  <Flex align={"center"} gap="sm">
                    <IconUser />
                    <Text fw={600}>Host:</Text>
                  </Flex>
                  <Text>{eventData.host.name}</Text>
                </Flex>
                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                  <Flex align={"center"} gap="sm">
                    <IconMapPin />
                    <Text fw={600}>Location:</Text>
                  </Flex>
                  <Text>{eventData.event.location}</Text>
                </Flex>
                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                  <Flex align={"center"} gap="sm">
                    <IconCalendar />
                    <Text fw={600}>Date:</Text>
                  </Flex>
                  <Text>{moment.tz(eventData.event.date, eventData.event.timezone).format('dddd MMMM DD YYYY')}</Text>
                </Flex>
                <Flex w={"100%"} justify={"space-between"} align={"center"}>
                  <Flex align={"center"} gap="sm">
                    <IconClock />
                    <Text fw={600}>Time:</Text>
                  </Flex>
                  <Text>{` ${formatAMPM(
                    eventData.event.meeting_time_start
                  )} - ${formatAMPM(eventData.event.meeting_time_end)}`}</Text>
                </Flex>
                <Text mt={"md"} color={"dark"} fw={500} fz={"sm"}>
                  Please submit any questions to: {eventData.host.email}
                </Text>
              </Group>
              <Divider mt={"xs"} mb="md" />
              <Group mt={"md"}>
                <Flex
                  mt={0}
                  miw={"100%"}
                  h={"100%"}
                  p={"md"}
                  direction={"column"}
                  gap={"md"}
                >
                  <Button variant="outline" h={"45px"} fz={"md"} p={"sm"} onClick={() => {
                      navigate(`/schedule/${eventData.enterpriseLink.id}?bookingId=${eventData.event.id}`);
                    }}>
                    Reschedule
                  </Button>
                  <Button
                    h={"45px"}
                    fz={"md"}
                    p={"sm"}
                    variant="outline"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to cancel your booking?"
                        )
                      ) {
                        axios
                          .delete(`/api/deleteEvent/${eventData.event.id}`)
                          .then((res) => {
                            setIsDeleted(true);
                            console.log(res.data[0])
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    }}
                  >
                    Cancel Booking
                  </Button>
                  <Button
                    h={"45px"}
                    fz={"md"}
                    p={"sm"}
                    variant={"light"}
                    onClick={() => {
                      navigate(`/schedule/${eventData.enterpriseLink.id}`);
                    }}
                  >
                    New Booking
                  </Button>
                  <Flex
                    mt={"md"}
                    miw={"100%"}
                    gap="sm"
                    align={"center"}
                    justify={"center"}
                  >
                    <IconInfoCircle size={15} />
                    <Text fz={"sm"} color={"dimmed"} italic>
                      All times are in{" "}
                      {eventData.event.timezone.replace("_", " ")} timezone
                    </Text>
                  </Flex>
                </Flex>
              </Group>
            </Card>
          </Center>
        </BackgroundImage>
      </Container>
    );
  }
}

export default BookingConfirmation;
