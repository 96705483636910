import React, { useState, useEffect } from "react";
import { IconInfoCircle, IconInfoCircleFilled } from "@tabler/icons-react";
import "../Styles/TipCycle.css";

const tips = {
  booking: [
    "**Customize Your Booking Page**: Personalize your booking page with your company's logo and colors to make it stand out to your clients.",
    "**Set Availability**: Ensure your availability is up-to-date to prevent overbooking and manage your schedule effectively.",
    "**Share Your Page**: Use the 'Share' button to easily send your booking page link, making it easy for clients to find you.",
  ],
  agenda: [
    "**Daily Overview**: Start your day with a glance at your agenda to stay on top of your meetings and appointments.",
    "**Cancel**: Need to make changes? Easily cancel appointments directly from your agenda to keep everything in order.",
    "**Filter Views**: Use the filter options to view your appointments for better planning.",
  ],
  team: [
    "**Invite Members**: Expand your team by inviting new members via email, allowing them to participate and manage meetings.",
    "**Assign Roles**: Assign roles to team members to define permissions and responsibilities within your scheduling system.",
  ],
  services: [
    "**Create New Services**: Add new services to your offerings, including details like duration, location, and the team member assigned.",
    "**Update Service Details**: Keep your services relevant by regularly updating the descriptions, location, and availability.",
    "**Promote Services**: Highlight popular or new services on your booking page to attract more bookings.",
  ],
  customers: [
    "**Review Client Information**: Before meetings, review client information and previous appointments for better preparation.",
    "**Follow-up Opportunities**: Use the customer list to identify follow-up opportunities and reach out to clients to keep them engaged.",
    "**Export Data**: Export your customer list for further analysis or to use in your marketing campaigns.",
  ],
  businessInfo: [
    "**Update Regularly**: Keep your business information, like contact details and addresses, up-to-date to ensure clients can easily reach you.",
    "**Enhance Your Profile**: Add a compelling description of your business to attract more clients and improve your profile's effectiveness.",
    "**Integrate Social Media**: Link your social media profiles to increase your online presence and provide clients with more ways to connect with you.",
  ],
};

function TipCycle({ currentPage }) {
  const [tipIndex, setTipIndex] = useState(0);
  const [currentTip, setCurrentTip] = useState(tips[currentPage][tipIndex]);
  const [fade, setFade] = useState("in"); // Track fade in/out state

  useEffect(() => {
    setTipIndex(0);
    setCurrentTip(tips[currentPage][0]);
    setFade("in");
  }, [currentPage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade("out");
      setTimeout(() => {
        setTipIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % tips[currentPage].length;
          setCurrentTip(tips[currentPage][newIndex]);
          return newIndex;
        });
        setFade("in");
      }, 500);
    }, 7000);

    return () => clearInterval(intervalId);
  }, [currentPage, tipIndex]);

  const parseTip = (tip) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const parts = tip.split(boldRegex);
    return parts.map((part, index) =>
      index % 2 === 1 ? <strong key={index}>{part}</strong> : part
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <IconInfoCircleFilled size={24} style={{ color: "#007BFF" }} />
      <div className={`tip-content ${fade}`}>{parseTip(currentTip)}</div>
    </div>
  );
}

export default TipCycle;