import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    () => localStorage.getItem("isLoggedIn") === "true"
  );

  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    const token = localStorage.getItem("token");
  
    if (token) {
      axios
        .get("/api//check-authentication", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => setIsLoggedIn(true))
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            setIsLoggedIn(false);
            localStorage.setItem("isLoggedIn", "false");
          } else {
            console.error(error);
          }
        });
    } else {
      setIsLoggedIn(false);
      localStorage.setItem("isLoggedIn", "false");
    }
  }, []);
  

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};
