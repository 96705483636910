import React, { useState, useEffect } from "react";
import "../../Styles/ScheduleView.css";
import {
  Image,
  Text,
  Button,
  Title,
  TextInput,
  Textarea,
  Modal,
  MantineProvider,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { createTheme, useMediaQuery } from "@mui/material";
import {
  daysOfWeek,
  convertAvailability,
  formatAMPM,
  addMinutesToHHMM,
  convertEventsTimezone,
  formatTimeToString,
} from "../Helper/TimeFormatting";
import axios from "axios";
import { Watch } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BlueMailLogo from "../../Images/BlueMail-App-icon.png";
import NoData from "../../Images/no_data.svg";
import ScheduleViewSidebar from "./ScheduleViewSidebar";
import { Notifications, notifications } from "@mantine/notifications";
const moment = require("moment-timezone");

function useDebouncedValue(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

function ScheduleView() {
  const { id } = useParams();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };
  const [selectedUser, setSelectedUser] = useState("Anyone");
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  const [submittedNewEvent, setSubmittedNewEvent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [teamData, setTeamData] = useState({});
  const [enterpriseData, setEnterpriseData] = useState({});
  const [nextTwoDays, setNextTwoDays] = useState([]);
  const [userTimezone, setUserTimezone] = useState(moment.tz.guess());
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedSlotDate, setSelectedSlotDate] = useState(new Date());
  const [events, setEvents] = useState({});
  const [googleEvents, setGoogleEvents] = useState({});
  const [microsoftEvents, setMicrosoftEvents] = useState({});
  const [meetingError, setMeetingError] = useState(false);
  const [users, setUsers] = useState([]);
  const [rescheduleEvent, setRescheduleEvent] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const bookingId = urlParams.get("bookingId");

  useEffect(() => {
    setIsLoading(true);

    const fetchCoreData = async () => {
      try {
        const teamDataResponse = await fetchTeamData(id);
        setTeamData(teamDataResponse);
        setSelectedDate(
          new Date(
            Date.now() + (teamDataResponse.no_booking_period ?? 0) * 60000
          )
        );

        const eventsResponse = await fetchEvents(
          teamDataResponse.enterprise_id
        );
        setEvents(eventsResponse);
        setUsers(await fetchUsers(teamDataResponse.members));

        fetchOptionalData(teamDataResponse.members);
      } catch (error) {
        console.error(error);
        setMeetingError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoreData();
  }, [id, bookingId]);

  const fetchOptionalData = async (memberIds) => {
    try {
      const [googleCalendarEvents, microsoftCalendarEvents] = await Promise.all(
        [fetchGoogleCalendarEvents(memberIds), fetchMicrosoftEvents(memberIds)]
      );

      setGoogleEvents(googleCalendarEvents);
      setMicrosoftEvents(microsoftCalendarEvents);

      if (bookingId) {
        try {
          const rescheduleEventData = await fetchRescheduleEvent(bookingId);

          if (rescheduleEventData) {
            setRescheduleEvent(rescheduleEventData);
            setName(rescheduleEventData?.event?.name);
            setEmail(rescheduleEventData?.event?.email);
            setComments(rescheduleEventData?.event?.comments);
          }

          console.log("Reschedule Event Data", rescheduleEventData);
        } catch (error) {
          console.error("Failed to fetch reschedule event data:", error);
        }
      }
    } catch (error) {
      console.error("Failed to fetch optional data:", error);
    }
  };

  async function fetchTeamData(id) {
    try {
      const res = await axios.get(`/api/getTeamLink/${id}`);
      setTeamData(res.data[0]);
      setEnterpriseData({
        name: res.data[0].enterprise_name,
        color_theme: res.data[0].enterprise_color_theme,
      });
      return res.data[0];
    } catch (error) {
      console.error("Failed to fetch team data:", error);
      setMeetingError(true);
    }
  }

  async function fetchUsers(userIds) {
    try {
      const res = await axios.post("/api/getUsersByIds", { userIds });
      // console.log("User Data", res.data);
      return res.data;
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setMeetingError(true);
    }
  }

  async function fetchEvents(teamId) {
    try {
      const res = await axios.get(`/api/getAllEnterpriseEvents/${teamId}`);
      // console.log("Team Data", teamId);
      // console.log("Enterprise Events", res.data[0]);
      return res.data[0];
    } catch (error) {
      console.error("Failed to fetch events:", error);
      setMeetingError(true);
    }
  }

  async function fetchRescheduleEvent(bookingId) {
    try {
      const res = await axios.get(`/api/getEvent/${bookingId}`);
      return res.data;
    } catch (error) {
      console.error("Failed to fetch reschedule event:", error);
    }
  }

  async function fetchGoogleCalendarEvents(ids) {
    try {
      const res = await axios.post(`/api/getGoogleEvents`, { ids });
      console.log("Google Events", res.data);
      return res.data;
    } catch (error) {
      console.error("Failed to fetch Google events:", error);
    }
  }

  async function fetchMicrosoftEvents(ids) {
    try {
      const res = await axios.post(`/api/getOutlookEvents`, { ids });
      // console.log("Microsoft Events", res.data)
      return res.data;
    } catch (error) {
      console.error("Failed to fetch Microsoft events:", error);
    }
  }

  const generateTimeSlots = (
    from,
    to,
    duration,
    date,
    events,
    buffer,
    userId
  ) => {
    let slots = [];
    let currentHour = Math.floor(from / 100);
    let currentMinute = from % 100;

    while (currentHour * 100 + currentMinute <= to) {
      let slot = currentHour * 100 + currentMinute;
      slots.push({ slot, userIds: [userId] });

      // Increase the currentMinute by duration
      currentMinute += duration;

      // If the currentMinute is >= 60, increase the currentHour by 1 and subtract 60 from currentMinute
      if (currentMinute >= 60) {
        currentHour += 1;
        currentMinute -= 60;
      }
    }

    return slots;
  };

  const isSlotCollision = (slot, date, events, buffer, userId) => {
    if (!Array.isArray(events)) {
      // console.error("Events is not an array", events);
      return false;
    }

    return events.some((event) => {
      const eventDate = moment(event.date).startOf("day");
      const slotDate = moment(date).startOf("day");

      const isSameDay = eventDate.isSame(slotDate);

      if (isSameDay) {
        let event_start = event.meeting_time_start - buffer;
        let event_end = event.meeting_time_end + buffer;

        if (slot >= event_start && slot < event_end) {
          console.log(userId, event.host);
          if (event.host === String(userId)) {
            console.log("Same host", event.host, userId, event);
            return true;
          }
        }
      }

      return false;
    });
  };

  useEffect(() => {
    const getNextTwoAvailableDays = () => {
      let availableDays = [];
      let tempDate = new Date(selectedDate);
      let daysChecked = 0;

      while (availableDays.length < 2 && teamData.availability) {
        let combinedSlots = [];

        // Iterate over each user's availability
        for (let userId in teamData.availability) {
          const userAvailability = teamData.availability[userId];
          if (!userAvailability) {
            continue; // Skip this user if they do not have an availability
          }

          const convertedAvailability = convertAvailability(
            userAvailability,
            teamData.user_timezone,
            userTimezone
          );

          const dayAvailability =
            convertedAvailability[daysOfWeek[tempDate.getDay()]];

          let convertedEvents = [];
          if (Array.isArray(events)) {
            convertedEvents = convertEventsTimezone(events, userTimezone);
          } else {
            convertedEvents = events;
          }

          if (
            dayAvailability &&
            dayAvailability.isOpen &&
            dayAvailability.slots.length > 0
          ) {
            console.log("Buffer", JSON.stringify(teamData, null, 2));
            const userSlots = dayAvailability.slots.flatMap((slot) =>
              generateTimeSlots(
                slot.from,
                slot.to,
                teamData.meeting_duration,
                tempDate,
                convertedEvents,
                teamData?.buffer_time || 0,
                userId
              )
            );

            console.log("User Slots", userSlots);

            // Merge the slots
            userSlots.forEach((userSlot) => {
              const existingSlot = combinedSlots.find(
                (slot) => slot.slot === userSlot.slot
              );
              if (existingSlot) {
                // If the slot is a collision for the user, remove the user from the slot
                if (
                  isSlotCollision(
                    userSlot.slot,
                    tempDate,
                    convertedEvents,
                    teamData?.buffer_time ?? 0,
                    userId
                  )
                ) {
                  const index = existingSlot.userIds.indexOf(userId);
                  if (index > -1) {
                    existingSlot.userIds.splice(index, 1);
                  }
                } else {
                  existingSlot.userIds.push(...userSlot.userIds);
                }
              } else {
                combinedSlots.push(userSlot);
              }
            });

            // After generating all slots, iterate over them and check for collisions
            combinedSlots.forEach((slot) => {
              slot.userIds.forEach((userId, index) => {
                if (
                  isSlotCollision(
                    slot.slot,
                    tempDate,
                    convertedEvents,
                    teamData.buffer_time ?? 0,
                    userId
                  )
                ) {
                  slot.userIds.splice(index, 1);
                }
              });
            });

            // Filter out slots that have no users available
            // console.log("Combined Slots", combinedSlots);
            combinedSlots = combinedSlots.filter(
              (slot) => slot.userIds.length !== 0
            );
            // console.log("Combined Slots After Removal", combinedSlots);
          }
        }

        combinedSlots.sort(function (a, b) {
          return a.slot - b.slot;
        });

        if (combinedSlots.length > 0) {
          availableDays.push({
            date: new Date(tempDate),
            slots: combinedSlots,
          });
        }

        tempDate.setDate(tempDate.getDate() + 1);
        daysChecked++;
      }

      return availableDays;
    };

    setNextTwoDays(getNextTwoAvailableDays());
  }, [selectedDate, teamData, userTimezone, events]);

  const getTimeSlots = (slots, duration) => {
    const interval = Math.floor(duration / 60) * 100 + (duration % 60);
    const timeSlots = slots.filter((slot, index, array) => {
      if (index === array.length - 1) {
        return;
      }
      const nextSlot = array[index + 1].slot;
      return nextSlot - slot.slot >= interval;
    });

    return timeSlots;
  };

  function getUserName(userData, userId) {
    // Convert the userId to a string if it's not already
    const userIdString = String(userId);

    const user = userData.find(
      (user) => String(user.enterprise_member_id) === userIdString
    );

    console.log("userData", userData);
    console.log("userId", userIdString);
    console.log("User!", user);

    return user ? user.name : "";
  }

  const formatTimeSlot = (timeSlot) => {
    let hours = Math.floor(timeSlot / 100)
      .toString()
      .padStart(2, "0");
    let minutes = (timeSlot % 100).toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      comments: "",
    },
    validate: {
      name: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      comments: (value) =>
        value.length < 2 ? "Comments must have at least 2 letters" : null,
    },
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmittedNewEvent(true);

    try {
      if (name.length < 2) {
        setError("Name must have at least 2 letters");
        return;
      }

      if (!/^\S+@\S+$/.test(email)) {
        setError("Invalid email");
        return;
      }

      // Define start and end times
      const startTime = selectedTime.slot;
      const endTime = addMinutesToHHMM(
        selectedTime.slot,
        teamData.meeting_duration
      );

      let dateString = moment(selectedSlotDate).format("YYYY-MM-DD");
      console.log("selectedSlotDate", selectedSlotDate);
      console.log("dateString", dateString);
      let formattedTimeSlot = formatTimeSlot(parseInt(selectedTime.slot));
      let combinedDateTime = moment.tz(
        `${dateString} ${formattedTimeSlot}`,
        "YYYY-MM-DD HH:mm",
        userTimezone
      );
      let time = combinedDateTime.utc().format();

      console.log("combinedDateTime", combinedDateTime);
      console.log("time", time);

      // Collect all the form data
      const formData = {
        name: name,
        email: email,
        comments: comments,
        event: teamData.name,
        host: selectedUser == "Anyone" ? selectedTime.userIds[0] : selectedUser,
        location: teamData.location,
        meetingTime: {
          from: startTime,
          to: endTime,
        },
        timezone: userTimezone,
        date: time,
        enterprise_link_id: teamData.id,
        source: "Scheduler",
      };

      if (rescheduleEvent !== null) {
        try {
          const response = await axios.post(
            `/api/updateEvent/${rescheduleEvent.event.id}`,
            formData
          );

          // If the event creation was successful, send a confirmation email
          if (response.status === 200) {
            setModalOpen(false);
            navigate(`/confirmation/${response.data[0].id}`);
          }
        } catch (error) {
          console.error("There was an error!", error);
          if (error.response && error.response.status === 409) {
            alert(error.response.data.message); // Alert the user
            setModalOpen(false);
            filterAvailableSlots(selectedTime.slot);
            setSelectedTime(null);
            setSelectedSlotDate(new Date());
          } else {
            setError("There was an error while making the booking.");
          }
        }
      } else {
        try {
          const response = await axios.post(
            "/api/createEnterpriseEvent",
            formData
          );
          console.log(response.data);

          // If the event creation was successful, send a confirmation email, also create a google event
          if (response.status === 200) {
            setModalOpen(false);
            navigate(`/confirmation/${response.data[0].id}`);
          }
        } catch (error) {
          console.error("There was an error!", error);
          if (error.response && error.response.status === 409) {
            alert(error.response.data.message); // Alert the user
            setModalOpen(false);
            filterAvailableSlots(selectedTime.slot);
            setSelectedTime(null);
            setSelectedSlotDate(new Date());
          } else {
            setError("There was an error while making the booking.");
          }
        }
      }
    } catch (error) {
      console.error("There was an error while making booking", error);
      setError("There was an error while making the booking", error);
    } finally {
      setSubmittedNewEvent(false);
    }
  };

  const filterAvailableSlots = (bookedSlot) => {
    const updatedDays = nextTwoDays.map((day) => ({
      ...day,
      slots: day.slots.filter((slot) => slot.slot !== bookedSlot),
    }));

    setNextTwoDays(updatedDays);
  };

  const BlueMailTheme = createTheme({
    palette: {
      primary: {
        main: "#134fb4",
      },
    },
  });

  if (isLoading) {
    return (
      <div className="Meeting-Loading">
        <Watch color="#134fb4" height={125} width={125} timeout={5000} />
      </div>
    );
  } else if (meetingError) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "2em",
          textAlign: "center",
        }}
      >
        <Image maw={300} src={NoData} />
        <h2>Meeting Doesn't Exist</h2>
      </div>
    );
  } else {
    return (
      <div className="ScheduleView-Background">
        <MantineProvider>
          <Notifications />
          <div className="ScheduleView-Container">
            <ScheduleViewSidebar
              teamData={teamData}
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
              userTimezone={userTimezone}
              setUserTimezone={setUserTimezone}
              users={users}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              enterpriseData={enterpriseData}
            />
            <div className="ScheduleView-Content">
              <div className="ScheduleView-Header">
                <div>
                  <Title order={4} color={teamData.enterprise_color_theme}>
                    Schedule an Appointment
                  </Title>
                  <Text color="rgba(0, 0, 0,0.75)">
                    To book an appointment, please select an available time slot
                    from the options below.
                  </Text>
                </div>
                <div id="BlueMail-Logo" />
              </div>
              <div className="ScheduleView-Display">
                {nextTwoDays.map((day, index) => (
                  <div className="ScheduleView-Day" key={day.date.toString()}>
                    <div className="ScheduleView-Day-Title">
                      <Title align="left" order={3}>
                        {moment(day.date).format("dddd")}
                      </Title>
                      <Title align="left" order={4}>
                        {moment(day.date).format("MMMM Do")}
                      </Title>
                    </div>
                    <div className="ScheduleView-TimeSlots">
                      {(() => {
                        let filteredSlots = day.slots;
                        if (selectedUser !== "Anyone") {
                          console.log("Selected User", selectedUser);
                          console.log("Filtered Slots", filteredSlots);
                          filteredSlots = filteredSlots.filter((slot) =>
                            slot.userIds.includes(selectedUser.toString())
                          );
                        }

                        if (filteredSlots.length === 0) {
                          return (
                            <Text color={"dimmed"} italic>
                              The user doesn't have any available slots on this
                              date.
                            </Text>
                          );
                        }

                        return getTimeSlots(
                          filteredSlots,
                          teamData.meeting_duration
                        ).map((slot) => (
                          <div
                            className="ScheduleView-Timeslot"
                            key={`${slot.slot}`}
                          >
                            <Button
                              onClick={() => {
                                setSelectedTime(slot);
                                setSelectedSlotDate(day.date);
                                setModalOpen(true);
                              }}
                              size="md"
                              className="TimeSlot"
                              variant="outline"
                              color={teamData.enterprise_color_theme}
                            >
                              {formatAMPM(slot.slot)}
                            </Button>
                          </div>
                        ));
                      })()}
                    </div>
                  </div>
                ))}
              </div>
              <div className="BlueMail-Footer">
                <div id="BlueMail-Logo-Footer" />
                <Title order={5}>BlueMail Scheduler</Title>
              </div>
            </div>
            {modalOpen && (
              <Modal
                // fullScreen={isMobile}
                opened={modalOpen}
                onClose={() => setModalOpen(false)}
                title="Appointment Confirmation"
              >
                <Title order={3}>
                  {teamData.enterprise_name} - {teamData.name}
                </Title>
                <Title
                  mt="0.25em"
                  order={5}
                  color={teamData.enterprise_color_theme}
                >
                  {selectedSlotDate.toDateString()} with{" "}
                  {selectedUser == "Anyone"
                    ? getUserName(users, selectedTime.userIds[0])
                    : getUserName(users, selectedUser)}
                </Title>
                <div className="Event-Scheduling-Info">
                  <TextInput
                    value={`${formatAMPM(selectedTime.slot)} - ${formatAMPM(
                      addMinutesToHHMM(
                        selectedTime.slot,
                        teamData.meeting_duration
                      )
                    )}`}
                    fullWidth
                    label={"Time"}
                    disabled
                  />

                  <TextInput
                    label={"Location"}
                    value={teamData.location}
                    disabled
                    fullWidth
                  />
                </div>
                <TextInput
                  label="Timezone"
                  value={userTimezone}
                  disabled
                  style={{ marginBottom: "1em" }}
                />

                <TextInput
                  label="Name"
                  placeholder="Your name"
                  defaultValue={
                    rescheduleEvent !== null ? rescheduleEvent?.event?.name : ""
                  }
                  onChange={(e) => setName(e.target.value)}
                  required
                  style={{ marginBottom: "1em" }}
                />

                <TextInput
                  label="Email"
                  defaultValue={
                    rescheduleEvent !== null
                      ? rescheduleEvent?.event?.email
                      : ""
                  }
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{ marginBottom: "1em" }}
                />

                <Textarea
                  label="Additional comments"
                  defaultValue={
                    rescheduleEvent !== null
                      ? rescheduleEvent?.event?.comments
                      : ""
                  }
                  placeholder="Your comments here..."
                  onChange={(e) => setComments(e.target.value)}
                  rows={3}
                  style={{ marginBottom: "1em" }}
                />

                <div className="mt-8 flex flex-col">
                  <Text className="font-medium text-sm text-red-500 mt-2 mb-2">
                    {error}
                  </Text>

                  <Button
                    color={teamData.enterprise_color_theme}
                    fullWidth
                    mb="2em"
                    variant="outline"
                    onClick={handleSubmit}
                    disabled={submittedNewEvent}
                  >
                    {rescheduleEvent !== null
                      ? "Reschedule Booking"
                      : "Confirm booking"}
                  </Button>
                </div>
              </Modal>
            )}
          </div>
        </MantineProvider>
      </div>
    );
  }
}

export default ScheduleView;
