// Generate shades from given hex input
import tinycolor from "tinycolor2";

export function isColorDark(color) {
  const colorValue = parseInt(color.replace("#", ""), 16);
  const r = (colorValue >> 16) & 255;
  const g = (colorValue >> 8) & 255;
  const b = colorValue & 255;
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance < 0.5;
}

export const mantineColors = {
  'red': '#FA5252',
  'pink': '#E64980',
  'grape': '#BE4BDB',
  'violet': '#7950F2',
  'indigo': '#4C6EF5',
  'blue': '#228BE6',
  'cyan': '#15AABF',
  'teal': '#12B886',
  'green': '#40C057',
  'lime': '#82C91E',
  'yellow': '#FAB005',
  'orange': '#FD7E14',
  'gray': '#868E96',
  'dark': '#25262B',
};