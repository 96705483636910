import React, { useState } from "react";
import "../../Styles/ScheduleView.css";
import {
  Avatar,
  Text,
  Select,
  Collapse,
  Paper,
  Container,
  Center,
  Flex,
  Badge,
  ActionIcon,
} from "@mantine/core";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  StaticDatePicker,
  PickersDay,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import {
  IconMapPin,
  IconClock,
  IconWorld,
  IconCalendar,
  IconChevronUp,
  IconChevronDown,
  IconUser,
  IconArrowUp,
  IconArrowDown,
} from "@tabler/icons-react";
import {
  formattedTimezones,
  daysOfWeek,
  convertAvailability,
  convetFromMiutes,
} from "../Helper/TimeFormatting";
import { mantineColors } from "../Helper/ThemeFormatting";
const moment = require("moment-timezone");

function ScheduleViewSidebar({
  teamData,
  selectedDate,
  handleDateChange,
  userTimezone,
  setUserTimezone,
  users,
  selectedUser,
  setSelectedUser,
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isOpen, setIsOpen] = useState(false);

  let initials = `${teamData.enterprise_name[0]}`;
  if (teamData.enterprise_name.includes(" ")) {
    let nameSplit = teamData.enterprise_name.split(" ");
    initials = `${nameSplit[0][0]}${nameSplit[1][0]}`;
  }

  const [sortOrder, setSortOrder] = useState("asc");

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const hexColor = mantineColors[teamData.enterprise_color_theme];

  const BlueMailTheme = createTheme({
    palette: {
      primary: {
        main: hexColor,
      },
    },
  });

  const formattedUsers = (users) => {
    console.log(users);
    if (users.length > 1) {
      const sortedUsers = users.sort((a, b) => {
        if (sortOrder === "asc") {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });

      return sortedUsers.map((user) => ({
        label: user.name,
        value: user.enterprise_member_id,
      }));
    } else {
      return [];
    }
  };

  return (
    <div className="ScheduleView-Sidebar">
      <div className="User-Info">
        <Paper
          padding="md"
          radius="sm"
          className="User-Header"
          onClick={() => isMobile && setIsOpen(!isOpen)}
        >
          <div className="User-Header">
            <Avatar
              size={60}
              radius={50}
              color={teamData.enterprise_color_theme}
              alt="User Image"
            >
              {initials}
            </Avatar>
            <div className="User-Text">
              <Text size="xl" weight={500}>
                {teamData.enterprise_name}
              </Text>
              <Text size="md" weight={300}>
                {teamData.name}
              </Text>
            </div>
            {isMobile &&
              (isOpen ? (
                <IconChevronUp className="User-Header-Arrow" />
              ) : (
                <IconChevronDown className="User-Header-Arrow" />
              ))}
          </div>
        </Paper>
        <Collapse in={!isMobile || isOpen} style={{ width: "100%" }}>
          <div className="User-Description">
            <div className="Event-Details">
              <Badge
                variant={"outline"}
                p={"xs"}
                size={"xs"}
                color={teamData.enterprise_color_theme}
              >
                <Flex align={"center"} gap={"0.5em"}>
                  <IconMapPin size={15} />
                  <Text size="xs">{teamData.location}</Text>
                </Flex>
              </Badge>
              <Badge
                variant={"outline"}
                p={"xs"}
                size={"xs"}
                color={teamData.enterprise_color_theme}
              >
                <Flex align={"center"} gap={"0.5em"}>
                  <IconClock size={15} />
                  <Text size="xs">
                    {convetFromMiutes(teamData.meeting_duration)}
                  </Text>
                </Flex>
              </Badge>
            </div>
            <Text
              size="sm"
              weight={300}
              color="rgba(0,0,0,0.55)"
              align="left"
              lineClamp={3}
            >
              {teamData.description}
            </Text>
            <Flex
              className="Event-Timezone"
              align={'flex-start'}
              justify={'flex-start'}
              direction={"column"}
              w={'100%'}
            >
              <Select
                variant="unstyled"
                placeholder="Start typing to see options"
                data={formattedTimezones()}
                searchable
                value={userTimezone}
                onChange={setUserTimezone}
                groupBy={(item) => item.group}
                icon={<IconWorld color="black" size="1.25em" />}
                size={isMobile ? "md" : "xs"}
                w={'100%'}
              />
              <Flex align={'center'} w={'100%'}>
                <Select
                  size={isMobile ? "md" : "xs"}
                  variant="unstyled"
                  placeholder="Start typing to see options"
                  searchable
                  onChange={setSelectedUser}
                  data={[
                    { label: "Anyone", value: "Anyone" },
                    ...formattedUsers(users),
                  ]}
                  icon={<IconUser color="black" size="1.25em" />}
                  value={selectedUser}
                  w={'100%'}
                />
                <ActionIcon onClick={toggleSortOrder}>
                  {sortOrder === "asc" ? <IconArrowUp /> : <IconArrowDown />}
                </ActionIcon>
              </Flex>
            </Flex>
          </div>
        </Collapse>
      </div>
      <Center w={"100%"} mb={"sm"}>
        <ThemeProvider theme={BlueMailTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {isMobile ? (
              <MobileDatePicker
                DialogProps={{
                  fullScreen: true,
                }}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: <IconCalendar />,
                      style: { width: "100%" },
                    },
                    variant: "outlined",
                    size: "medium",
                    border: "none",
                    style: { width: "85%" },
                    InputLabelProps: { style: { width: "100%" } },
                  },
                }}
                variant="inline"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <Text {...params} />}
                shouldDisableDate={(day) => {
                  if (!teamData.availability) {
                    console.error("teamData.availability is undefined");
                    return true; // disable all dates if availability is undefined
                  }

                  const dayIndex = (day.getDay() + 7) % 7;
                  const dayOfWeek = daysOfWeek[dayIndex];

                  // Check if there's at least one user available on this day
                  const isAnyUserAvailable = Object.values(
                    teamData.availability
                  ).some((userAvailability) => {
                    if (!userAvailability) {
                      return false; // skip this user if they do not have an availability
                    }

                    const convertedAvailability = convertAvailability(
                      userAvailability,
                      teamData.user_timezone,
                      userTimezone
                    );
                    const dayAvailability = convertedAvailability[dayOfWeek];
                    return (
                      dayAvailability.isOpen && dayAvailability.slots.length > 0
                    );
                  });

                  return !isAnyUserAvailable;
                }}
                minDate={
                  new Date(
                    Date.now() + (teamData.no_booking_period ?? 0) * 60000
                  )
                }
                {...(teamData.booking_limit !== null
                  ? {
                      maxDate: new Date(
                        Date.now() + teamData.booking_limit * 60000
                      ),
                    }
                  : {})}
              />
            ) : (
              <Flex
                w={"100%"}
                h={"100%"}
                justify={"center"}
                style={{
                  transform: "scale(0.95)",
                  transformOrigin: "top center",
                }}
                align={"center"}
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <Text {...params} />}
                  shouldDisableDate={(day) => {
                    if (!teamData.availability) {
                      console.error("teamData.availability is undefined");
                      return true; // disable all dates if availability is undefined
                    }

                    const dayIndex = (day.getDay() + 7) % 7;
                    const dayOfWeek = daysOfWeek[dayIndex];

                    // Check if there's at least one user available on this day
                    const isAnyUserAvailable = Object.values(
                      teamData.availability
                    ).some((userAvailability) => {
                      if (!userAvailability) {
                        return false; // skip this user if they do not have an availability
                      }

                      const convertedAvailability = convertAvailability(
                        userAvailability,
                        teamData.user_timezone,
                        userTimezone
                      );
                      const dayAvailability = convertedAvailability[dayOfWeek];
                      return (
                        dayAvailability.isOpen &&
                        dayAvailability.slots.length > 0
                      );
                    });

                    return !isAnyUserAvailable;
                  }}
                  minDate={
                    new Date(
                      Date.now() + (teamData.no_booking_period ?? 0) * 60000
                    )
                  }
                  {...(teamData.booking_limit !== null
                    ? {
                        maxDate: new Date(
                          Date.now() + teamData.booking_limit * 60000
                        ),
                      }
                    : {})}
                  renderDay={(day, DayComponentProps) => {
                    const dayIndex = (day.getDay() + 6) % 7;
                    const convertedAvailability = convertAvailability(
                      teamData.availability, // <-- changed from teamData.user_availability
                      teamData.user_timezone,
                      userTimezone
                    );
                    const dayAvailability =
                      convertedAvailability[daysOfWeek[dayIndex]];
                    if (
                      dayAvailability.isOpen &&
                      dayAvailability.slots.length > 0
                    ) {
                      return (
                        <PickersDay
                          {...DayComponentProps}
                          sx={{ color: "lightblue" }}
                        />
                      );
                    }
                    return <PickersDay {...DayComponentProps} />;
                  }}
                />
              </Flex>
            )}
          </LocalizationProvider>
        </ThemeProvider>
      </Center>
    </div>
  );
}

export default ScheduleViewSidebar;
