import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Divider,
  Menu,
  Container,
  Text,
  Title,
  Modal,
  Grid,
  TextInput,
  Select,
  Checkbox,
  Textarea,
  Collapse,
  MultiSelect,
  Paper,
  Flex,
  Group,
  Card,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  IconCashBanknote,
  IconCopy,
  IconEdit,
  IconMail,
  IconMapPin,
  IconPlus,
  IconRecordMail,
  IconTrash,
  IconUser,
  IconMinus,
  IconWorld,
  IconExclamationCircle,
  IconExternalLink,
  IconSearch,
} from "@tabler/icons-react";
import moment from "moment";
import React, { useContext } from "react";
import { CiMenuKebab } from "react-icons/ci";
import axios from "axios";
import {
  daysOfWeek,
  timeSchedule,
  formattedTimezones,
  timePeriodToMinutes,
} from "../../Helper/TimeFormatting";
import "../../../Styles/EnterpriseTabs.css";
import { useState, useEffect } from "react";
import { AuthContext } from "../../AuthContext";
import EditEnterprise from "../EditEnterprise";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import TipCycle from "../../TipCycle";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar radius={"xl"} src={image} />

        <Flex direction={"column"}>
          <Text>{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </Flex>
      </Group>
    </div>
  )
);

function Services({
  bookingPageData,
  teamScheduleLinks,
  setTeamScheduleLinks,
}) {
  const [teamModalOpened, setTeamModalOpened] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isTeamsModalOpened, setIsTeamsModalOpened] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState(null);
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const newTeamForm = useForm({
    initialValues: {
      teamName: "",
      meetingDescription: "",
      meetingDuration: 30,
      userTimezone: moment.tz.guess(),
      userAvailability: {},
      noBookingPeriod: "",
      bookingLimit: "",
      meetingBufferTime: "",
      location: "",
      members: [],
    },

    validate: {
      teamName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      location: (value) =>
        value.length < 2 ? "Location must have at least 2 letters" : null,
      meetingDescription: (value) =>
        value.length < 10
          ? "Description must be at least 10 characters long"
          : null,
      members: (value) =>
        value.length > 0 ? null : "Must add at least one member to team",

      userAvailability: (value) => {
        if (Object.keys(value).length === 0) {
          return "Must add at least one member to team";
        }
        let hasAvailability = false;
        for (let userId of newTeamForm.values.members) {
          let userHasAvailability = false;
          if (value[userId]) {
            for (let day in value[userId]) {
              let slots = [...value[userId][day].slots];
              slots.sort((a, b) => a.from - b.from);
              for (let i = 0; i < slots.length; i++) {
                if (slots[i].from >= slots[i].to) {
                  return "The 'start' time must be earlier than the 'end' time";
                }
                if (i > 0 && slots[i].from < slots[i - 1].to) {
                  return "Time slots must not overlap";
                }
                if (value[userId][day].isOpen && slots.length > 0) {
                  userHasAvailability = true;
                }
              }
            }
          }
          if (!userHasAvailability) {
            return `Must input availability for member ${
              getMemberNameById(userId).name
            }`;
          }
          hasAvailability = true;
        }
        return hasAvailability
          ? null
          : "Must input availability for all selected members";
      },
    },
  });

  const isMobile = useMediaQuery("(max-width: 600px)");

  const getMemberNameById = (memberId) => {
    const member = bookingPageData.members.find(
      (member) => member.id === memberId
    );

    console.log("Member", JSON.stringify(member, null, 2));
    return member ? member : "";
  };

  const [tags, setTags] = useState([]);
  const [input, setInput] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && input) {
      e.preventDefault();
      setTags([...tags, input]);
      setInput("");
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleMemberSelect = (memberId) => {
    setSelectedMember(memberId);

    if (!newTeamForm.values.userAvailability[memberId]) {
      newTeamForm.setFieldValue(`userAvailability.${memberId}`, {
        sun: { isOpen: false, slots: [] },
        mon: { isOpen: false, slots: [] },
        tue: { isOpen: false, slots: [] },
        wed: { isOpen: false, slots: [] },
        thu: { isOpen: false, slots: [] },
        fri: { isOpen: false, slots: [] },
        sat: { isOpen: false, slots: [] },
      });
    }
  };

  const handleCheckboxChange = (day) => {
    if (!selectedMember) return;
    const userAvailability =
      newTeamForm.values.userAvailability[selectedMember];
    const daySlots = userAvailability[day];
    newTeamForm.setFieldValue(`userAvailability.${selectedMember}.${day}`, {
      isOpen: !daySlots.isOpen,
      slots: daySlots.isOpen ? [] : [{ from: 900, to: 1700 }],
    });
  };

  const copyToClipboard = (text) => {
    const url = `${window.location.origin}/schedule/${text}`;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url).then(
        function () {
          console.log("Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Could not copy text: ", err);
        }
      );
    } else if (window.clipboardData) {
      // Internet Explorer
      window.clipboardData.setData("Text", url);
      console.log("Copying to clipboard was successful!");
    } else {
      // Fallback for browsers that do not support Clipboard API nor clipboardData
      var textArea = document.createElement("textarea");
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    }
  };

  const handleAddSlot = (day) => {
    if (!selectedMember) return;
    const userAvailability =
      newTeamForm.values.userAvailability[selectedMember] || {};
    const daySlots = userAvailability[day] || { isOpen: false, slots: [] };
    newTeamForm.setFieldValue(`userAvailability.${selectedMember}.${day}`, {
      ...daySlots,
      slots: [...daySlots.slots, { from: 900, to: 1700 }],
    });
  };

  const handleDeleteSlot = (day, index) => {
    if (!selectedMember) return;
    const userAvailability =
      newTeamForm.values.userAvailability[selectedMember] || {};
    const daySlots = userAvailability[day] || { isOpen: false, slots: [] };
    const updatedSlots = daySlots.slots.filter((_, i) => i !== index);
    newTeamForm.setFieldValue(`userAvailability.${selectedMember}.${day}`, {
      ...daySlots,
      slots: updatedSlots,
    });
  };

  const handleTimeChange = (day, index, position, value) => {
    if (!selectedMember) return;
    const userAvailability =
      newTeamForm.values.userAvailability[selectedMember] || {};
    const daySlots = userAvailability[day] || { isOpen: false, slots: [] };
    const updatedSlots = [...daySlots.slots];
    updatedSlots[index][position] = parseInt(value, 10);
    newTeamForm.setFieldValue(`userAvailability.${selectedMember}.${day}`, {
      ...daySlots,
      slots: updatedSlots,
    });
  };

  const deleteTeamLink = (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this service? \nThis will delete all associated appointments."
      )
    ) {
      axios
        .delete(`/api/deleteTeamLink/${id}`)
        .then((response) => {
          console.log(response.data);
          setTeamScheduleLinks(
            teamScheduleLinks.filter((link) => link.id !== id)
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  let filteredAndSortedLinks = teamScheduleLinks;

  if (searchQuery) {
    filteredAndSortedLinks = filteredAndSortedLinks.filter((link) =>
      link.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  if (sortOption) {
    filteredAndSortedLinks.sort((a, b) => {
      switch (sortOption) {
        case "nameAsc":
          return a.name.localeCompare(b.name);
        case "nameDesc":
          return b.name.localeCompare(a.name);
        case "durationAsc":
          return a.meeting_duration - b.meeting_duration;
        case "durationDesc":
          return b.meeting_duration - a.meeting_duration;
        default:
          return 0;
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    const isFormValid = newTeamForm.validate();
    console.log(isFormValid);

    try {
      if (isFormValid.hasErrors) {
        console.log("Form invalid");
        setIsLoading(false);
        setError("Please fill out all required fields");
        return;
      } else {
        const formValues = newTeamForm.values;

        // Filter out the availability of the users that are not included in the members array
        const filteredAvailability = Object.keys(formValues.userAvailability)
          .filter((userId) => formValues.members.includes(Number(userId)))
          .reduce((obj, key) => {
            obj[key] = formValues.userAvailability[key];
            return obj;
          }, {});

        formValues.userAvailability = filteredAvailability;

        formValues.tags = tags;

        formValues.enterprise_id = bookingPageData[0].id;

        axios
          .post("/api/createTeamLink", formValues, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            withCredentials: true,
          })
          .then((response) => {
            console.log("Success", response.data);
            setTeamScheduleLinks((prevLinks) => [
              ...prevLinks,
              response.data[0],
            ]);
            setTeamModalOpened(false);
            newTeamForm.reset();
            setSelectedMember(null);
            setTags([]);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            setError(`Error: ${error.response.data.message}`);
          });
      }
    } catch (error) {
      console.log(error);
      setError(`Error: ${error.response.data.message}`);
      setIsLoading(false);
    } finally {
      setError('');
    }
  };

  return (
    <Container
      miw={"100%"}
      mih={"100%"}
      mah={"100vh"}
      pl={isMobile ? "0" : "80px"}
    >
      <Title
        p="xs"
        style={{ borderBottom: "solid 1px rgba(0,0,0,0.15)" }}
        order={2}
      >
        Services
      </Title>
      <Flex
        direction={isMobile ? "column" : "row"}
        gap={"md"}
        style={{ overflow: "auto" }}
        justify={"space-between"}
        mt={"lg"}
        mb={"sm"}
        w={"100%"}
      >
        <TextInput
          placeholder="Search for services"
          icon={<IconSearch size={"1em"} />}
          w={isMobile ? "100%" : "80%"}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <Select
          placeholder="Sorting Options"
          data={[
            { value: "nameAsc", label: "Name (A-Z)" },
            { value: "nameDesc", label: "Name (Z-A)" },
            { value: "durationAsc", label: "Duration (Low-High)" },
            { value: "durationDesc", label: "Duration (High-Low)" },
          ]}
          onChange={(value) => setSortOption(value)}
        />
      </Flex>
      {!isMobile && <TipCycle currentPage="services" />}
      <Grid
        mt={"sm"}
        gutter={"md"}
        w={"100%"}
        h={"100%"}
        style={{ overflow: "auto" }}
      >
        {teamScheduleLinks &&
          Array.isArray(teamScheduleLinks) &&
          filteredAndSortedLinks.map((link) => (
            <Card
              className="TeamManagement-Card"
              key={link.id}
              style={{
                maxHeight: "100px",
                marginTop: "1em",
                marginLeft: "1em",
                overflow: "visible",
              }}
              shadow="xs"
            >
              <div className="TeamManagement-Card-Header">
                <div className="TeamManagement-Card-Header-Avatars">
                  {link.members.slice(0, 3).map((member, index) => {
                    let memberName = getMemberNameById(member).name;
                    let profile_image =
                      getMemberNameById(member).profile_image || "";
                    let initials = memberName.includes(" ")
                      ? `${memberName.split(" ")[0][0]}${
                          memberName.split(" ")[1][0]
                        }`
                      : memberName[0];

                    return (
                      member && (
                        <Tooltip
                          multiline
                          maw={150}
                          label={
                            index === 0 && link.members.length > 3
                              ? link.members
                                  .slice(2)
                                  .map((m) => getMemberNameById(m).name)
                                  .join(",\n")
                              : memberName
                          }
                        >
                          <Avatar
                            key={member.id}
                            ml={index !== 0 ? "-0.5em" : "0"}
                            radius="50%"
                            style={{
                              position: "relative",
                              zIndex: link.members.length - index,
                              border: "solid 1px rgba(0,0,0,0.05)",
                            }}
                            className="shadow-md"
                            color={`${bookingPageData[0]?.color_theme}`}
                            src={
                              index === 0 && link.members.length > 3
                                ? ""
                                : profile_image
                            }
                          >
                            {index === 0 && link.members.length > 3
                              ? `+${link.members.length - 2}`
                              : `${initials}`}
                          </Avatar>
                        </Tooltip>
                      )
                    );
                  })}
                </div>
                <Menu
                  shadow="md"
                  withArrow
                  width={200}
                  position="bottom-center"
                >
                  <Menu.Target>
                    <ActionIcon size="lg">
                      <CiMenuKebab size={20} />
                    </ActionIcon>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Label>Link Actions</Menu.Label>
                    <Menu.Item
                      icon={<IconExternalLink size={20} />}
                      size="sm"
                      onClick={() =>
                        window.open(
                          `${window.location.origin}/schedule/${link.id}`,
                          "_blank"
                        )
                      }
                    >
                      View
                    </Menu.Item>
                    <Menu.Item
                      icon={<IconCopy size={20} />}
                      size="sm"
                      onClick={() => copyToClipboard(`${link.id}`)}
                    >
                      Copy
                    </Menu.Item>

                    {bookingPageData.role !== "Member" && (
                      <Menu.Item
                        onClick={() => {
                          setSelectedTeam(link);
                          setTimeout(() => setIsTeamsModalOpened(true), 0);
                        }}
                        icon={<IconEdit size={20} />}
                      >
                        Edit
                      </Menu.Item>
                    )}

                    <Menu.Divider />
                    {bookingPageData.role !== "Member" && (
                      <>
                        <Menu.Label>Danger zone</Menu.Label>
                        <Menu.Item
                          color={`red`}
                          icon={<IconTrash size={20} />}
                          onClick={() => deleteTeamLink(link.id)}
                        >
                          Delete Team
                        </Menu.Item>
                      </>
                    )}
                  </Menu.Dropdown>
                </Menu>
              </div>
              <div className="TeamManagement-Card-Content">
                <h2 className="text-2xl font-semibold mt-3 mb-2">
                  {link.name}
                </h2>
                <div className="flex flex-col gap-1 mt-2 ml-2">
                  {link.tags.map((tag) => (
                    <p className="text-sm text-gray-500">• {tag}</p>
                  ))}
                </div>
              </div>

              {/* <div className="TeamManagement-Card-Data">
                <Text fz="xs" tt="uppercase" fw={500} c="dimmed">
                  Meetings Booked
                </Text>
                <Text fz="lg" fw={500}>
                  0 / 80
                </Text>
                <Progress
                  color={`${bookingPageData[0]?.color_theme}`}
                  value={0}
                  mt="0.5em"
                  size="md"
                  radius="xl"
                />
              </div> */}
            </Card>
          ))}
        {bookingPageData.role !== "Member" && (
          <Card
            className="TeamManagement-Card"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxHeight: "100px",
              marginTop: "1em",
              marginLeft: "1em",
            }}
            shadow="xs"
          >
            <ActionIcon
              mih={"250px"}
              onClick={() => setTeamModalOpened(true)}
              style={{ width: "100%", height: "100%" }}
            >
              <IconPlus size={"30%"} />
            </ActionIcon>
          </Card>
        )}
      </Grid>
      <Modal
        title="New Service"
        opened={teamModalOpened}
        onClose={() => setTeamModalOpened(false)}
        size={"50em"}
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <Title className="mb-4" order={3}>
          Service Info
        </Title>
        <TextInput
          placeholder="Marketing"
          label="Service Name"
          fullWidth
          withAsterisk
          {...newTeamForm.getInputProps("teamName")}
          mt="0.25em"
          className="mb-6"
        />

        <MultiSelect
          label="Members"
          mt="0.5em"
          className="mb-6"
          withAsterisk
          itemComponent={SelectItem}
          description="Select members for this service"
          data={bookingPageData.members.map((member) => ({
            value: member.id,
            label: member.name,
            description: member.email,
            image: member.profile_image,
          }))}
          multi
          placeholder="Select members"
          {...newTeamForm.getInputProps("members")}
        />

        <div className="mb-6">
          <TextInput
            placeholder="Type a tag and press enter"
            value={input}
            onChange={(event) => setInput(event.currentTarget.value)}
            onKeyDown={handleKeyDown}
            label={"Tags"}
            mt={"0.75em"}
            description={
              "Enter tags associated with this service or click on tags to remove them"
            }
          />
          <Paper padding="md" style={{ marginTop: "0.65em" }}>
            {tags.map((tag) => (
              <Badge
                color="blue"
                key={tag}
                onClick={() => removeTag(tag)}
                style={{
                  marginRight: "0.5em",
                  marginBottom: "0.5em",
                  cursor: "pointer",
                }}
              >
                {tag}
              </Badge>
            ))}
          </Paper>
        </div>

        <Textarea
          placeholder="Include a description of your service or any details your attendees should know"
          mt="0.75em"
          label="Meeting Description"
          fullWidth
          withAsterisk
          className="mb-6"
          {...newTeamForm.getInputProps("meetingDescription")}
        />

        <TextInput
          placeholder="BlueMail Meet"
          label="Location"
          fullWidth
          withAsterisk
          {...newTeamForm.getInputProps("location")}
          mt="0.75em"
          className="mb-6"
        />

        <Divider mt="1em" mb="0.5em" className="mb-4 mt-8" />
        <div className="flex justify-between items-end mb-4">
          <div className="Availability-Title">
            <Title order={3}>Service Availability</Title>
            <Title
              color="rgba(0,0,0,0.5)"
              className="font-light mt-1"
              order={6}
            >
              Input weekly general availability for each member
            </Title>
            {newTeamForm.errors.userAvailability && (
              <Flex align={"center"} justify={"flex-start"} gap={"md"}>
                <IconExclamationCircle color={`red`} size={"1em"} />
                <Text mt={"1em"} mb={"1em"} color={`red`} italic size="sm">
                  {newTeamForm.errors.userAvailability}
                </Text>
              </Flex>
            )}
          </div>
          <Select
            variant="unstyled"
            placeholder="Start typing to see options"
            data={formattedTimezones()}
            searchable
            value={newTeamForm.values.userTimezone}
            {...newTeamForm.getInputProps("userTimezone")}
            groupBy={(item) => item.group}
            icon={<IconWorld color="black" size="1.25em" />}
            mt="0.25em"
            mb="0.25em"
            ml="auto"
          />
        </div>
        <Select
          variant="unstyled"
          className="border mt-2 mb-4 border-gray-400 rounded-md px-2"
          placeholder="Select a member"
          data={newTeamForm.values.members.map((memberId) => ({
            label: getMemberNameById(memberId).name,
            value: memberId,
          }))}
          value={selectedMember}
          onChange={handleMemberSelect}
        />

        {selectedMember ? (
          <div>
            <h2>{getMemberNameById(selectedMember).name}'s Availability</h2>
            <div className="Availability-Grid">
              {daysOfWeek.map((day) => (
                <div className="Availability-Item">
                  <Checkbox
                    checked={
                      newTeamForm.values.userAvailability[selectedMember] &&
                      newTeamForm.values.userAvailability[selectedMember][
                        day
                      ] &&
                      newTeamForm.values.userAvailability[selectedMember][day]
                        .slots.length > 0
                    }
                    label={day.charAt(0).toUpperCase() + day.slice(1)}
                    onChange={() => handleCheckboxChange(day)}
                  />
                  <Collapse
                    in={
                      newTeamForm.values.userAvailability[selectedMember] &&
                      newTeamForm.values.userAvailability[selectedMember][
                        day
                      ] &&
                      newTeamForm.values.userAvailability[selectedMember][day]
                        .isOpen
                    }
                  >
                    {newTeamForm.values.userAvailability[selectedMember] &&
                    newTeamForm.values.userAvailability[selectedMember][day]
                      ? newTeamForm.values.userAvailability[selectedMember][
                          day
                        ].slots.map((slot, index) => (
                          <div className="Availability-Inputs">
                            <Select
                              data={timeSchedule}
                              value={slot.from}
                              placeholder="From"
                              onChange={(value) =>
                                handleTimeChange(day, index, "from", value)
                              }
                              width="auto"
                            />
                            <Text> - </Text>
                            <Select
                              data={timeSchedule}
                              value={slot.to}
                              placeholder="To"
                              onChange={(value) =>
                                handleTimeChange(day, index, "to", value)
                              }
                              width="auto"
                            />

                            <ActionIcon
                              onClick={() => handleDeleteSlot(day, index)}
                            >
                              <IconMinus />
                            </ActionIcon>
                          </div>
                        ))
                      : null}
                    <Button
                      onClick={() => handleAddSlot(day)}
                      leftIcon={<IconPlus size="2em" />}
                      variant="unstyled"
                      size="75%"
                      mt="2em"
                    >
                      Add Time Interval
                    </Button>
                  </Collapse>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Text className="mb-6 mt-6 font-medium text-sm underline underline-offset-8 text-red-500">
            Select members for this service above, and then select each member
            to implement availability.
          </Text>
        )}

        <Divider mt="1em" mb="0.5em" />
        <Title className="mt-4" order={3}>
          Advanced Booking Options
        </Title>
        <div className="flex flex-col gap-6 mt-4 mb-2">
          <Select
            label="Meeting Duration"
            description="How long should attendees be able to schedule a meeting?"
            data={[
              { value: 15, label: "15 minutes" },
              { value: 30, label: "30 minutes" },
              { value: 60, label: "1 hour" },
            ]}
            placeholder="Choose a value"
            {...newTeamForm.getInputProps("meetingDuration")}
          />
          <Select
            clearable
            label="Buffer Time Between Meetings"
            description="How long should the gap be between meetings?"
            data={[
              { value: 5, label: "5 minutes" },
              { value: 15, label: "15 minutes" },
              { value: 30, label: "30 minutes" },
              { value: 60, label: "1 hour" },
            ]}
            placeholder="Choose a value"
            {...newTeamForm.getInputProps("meetingBufferTime")}
          />
          <Select
            clearable
            label="Earliest Booking Time"
            description="How many days from today can users start booking meetings?"
            data={[
              { value: 1440, label: "1 day" },
              { value: 2880, label: "2 days" },
              { value: 4320, label: "3 days" },
              { value: 10080, label: "1 week" },
            ]}
            placeholder="Choose a value"
            {...newTeamForm.getInputProps("noBookingPeriod")}
          />
          <Select
            clearable
            label="Latest Booking Time"
            description="How far into the future can users book meetings?"
            data={[
              { value: 10080, label: "1 week" },
              { value: 20160, label: "2 weeks" },
              { value: 40320, label: "1 month" },
              { value: 241920, label: "6 months" },
              { value: 483840, label: "1 year" },
            ]}
            placeholder="Choose a value"
            {...newTeamForm.getInputProps("bookingLimit")}
          />
        </div>

        <div className="mt-6 flex flex-col">
          <p className="text-sm text-red-500 mb-2">{error}</p>

          <Button
            loading={isLoading}
            type="submit"
            mb="1em"
            variant="outline"
            w="100%"
            onClick={(event) => {
              handleSubmit(event);
            }}
          >
            Create New Service
          </Button>
        </div>
      </Modal>
      {selectedTeam && (
        <EditEnterprise
          selectedTeam={selectedTeam}
          isTeamsModalOpened={isTeamsModalOpened}
          setTeamScheduleLinks={setTeamScheduleLinks}
          bookingPageData={bookingPageData}
          onClose={() => {
            setIsTeamsModalOpened(false);
            setSelectedTeam(null);
          }}
        />
      )}
    </Container>
  );
}

export default Services;
