import {
  Modal,
  Card,
  Avatar,
  Menu,
  Badge,
  TextInput,
  Divider,
  Title,
  Text,
  Button,
  ColorPicker,
  Select,
  ActionIcon,
  Autocomplete,
  MultiSelect,
  FileInput,
  ColorInput,
  Textarea,
  MantineProvider,
  Col,
  Paper,
  useMantineTheme,
  Radio,
  Box,
  Container,
  Flex,
  Grid,
} from "@mantine/core";
import { useMediaQuery } from "@mui/material";
import {
  IconAppWindow,
  IconCheck,
  IconDoorExit,
  IconMapPin,
  IconPlus,
  IconTrash,
  IconUpload,
  IconView360,
  IconX,
} from "@tabler/icons-react";
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { useForm } from "@mantine/form";
import axios from "axios";
import HeaderMenu from "./HeaderMenu";
import { CiMenuKebab } from "react-icons/ci";
import TipCycle from "../TipCycle";

function Home() {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

  const [bookingPages, setBookingPages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newBookingPage, setNewBookingPage] = useState(false);
  const [members, setMembers] = useState([]);

  const [error, setError] = useState("");

  const [email, setEmail] = useState("");

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

  const [image, setImage] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.log("No token found");
      return;
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };

    Promise.all([
      axios.get("/api/getEnterprises", config),
      axios.get("/api/getUserProfile", config),
    ])
      .then(([enterprisesRes, profileRes]) => {
        setBookingPages(enterprisesRes.data[0]);
        setMembers([{ email: profileRes.data.email, role: "Owner" }]);
        console.log("Emails", profileRes.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [isLoggedIn]);

  const [name, setName] = useState("");
  const [theme, setTheme] = useState("blue");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [website, setWebsite] = useState("");

  const baseColors = [
    "red",
    "pink",
    "violet",
    "indigo",
    "blue",
    "cyan",
    "teal",
    "green",
    "lime",
    "yellow",
    "orange",
    "brown",
  ];

  const handleAddMember = (e) => {
    e.preventDefault();
    if (email) {
      if (emailRegex.test(email)) {
        if (!members.find((member) => member.email === email)) {
          setMembers([...members, { email, role: "Member" }]);
          setEmail("");
        } else {
          console.log("Email already present");
        }
      } else {
        console.log("Invalid email");
      }
    }
  };

  const handleRoleChange = (index, role) => {
    const newMembers = [...members];
    newMembers[index].role = role;
    setMembers(newMembers);
  };

  const handleImageChange = (file) => {
    if (!file) return;

    console.log(file);

    const fileUrl = URL.createObjectURL(file);
    setFileToUpload(file);
    setImage(fileUrl);
  };

  const handleRemoveMember = (index) => {
    const newMembers = [...members];
    newMembers.splice(index, 1);
    setMembers(newMembers);
  };

  const handleViewClick = (id) => {
    navigate(`/book/${id}`);
  };

  const deleteEnterprise = (id) => {
    axios
      .delete(`/api/deleteEnterprise/${id}`)
      .then((response) => {
        console.log(response.data);
        setBookingPages(bookingPages.filter((link) => link.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Validation
    if (name.length < 2) {
      setError("Name must have at least 2 letters");
      setIsLoading(false);
      return;
    } else if (website.length > 0 && !urlRegex.test(website)) {
      setError("Please enter a valid URL, Ex: www.bluemail.me");
      setIsLoading(false);
      return;
    }

    console.log("Members", members);
    console.log("File", fileToUpload);
    console.log("Name", name);
    console.log("Theme", theme);
    console.log("Description", description);
    console.log("Location", location);
    console.log("Website", website);

    if (members.some((member) => !member.email)) {
      setError("All members must have an email");
      setIsLoading(false);
      return;
    }

    if (fileToUpload) {
      const formData = new FormData();
      formData.append("image", fileToUpload);
      formData.append("name", name);

      try {
        const uploadResponse = await axios.post(
          "/api/imageUpload",
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            withCredentials: true,
          }
        );
      } catch (error) {
        console.error("Error uploading image:", error);
        setError("Error uploading image");
      }
    }

    try {
      const createResponse = await axios.post(
        "/api/createEnterprise",
        {
          name,
          theme,
          members: JSON.stringify(members),
          description,
          location,
          website,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          withCredentials: true,
        }
      );

      console.log("Success", createResponse.data);
      console.log("Success", createResponse.data.enterprise[0]);
      const newBookingPage = {
        ...createResponse.data.enterprise[0],
        role: "Owner",
        logo_url: image
      };
      setBookingPages((prevBookingPages) => [
        ...prevBookingPages,
        newBookingPage,
      ]);

      setNewBookingPage(false);
      resetFormFields();
    } catch (error) {
      console.error("Error creating enterprise:", error);
      setError(error.response.data.message || "Error creating enterprise");
    } finally {
      setIsLoading(false);
    }
  };

  const resetFormFields = () => {
    setName("");
    setTheme("blue");
    setDescription("");
    setLocation("");
    setWebsite("");
    setFileToUpload(null);
    setImage(null);
    setError("");
    let onlyOwner = members.filter((member) => member.role === "Owner");
    setMembers(onlyOwner);
  };

  return (
    <Container miw={"100vw"} h={"100vh"}>
      <HeaderMenu setBookingPages={setBookingPages} />
      {isLoggedIn && (
        <>
          <div className="w-full pl-8 pr-8 h-full pt-[80px] flex flex-col">
            <div className="w-full h-16 border-b-[1px] bg-white flex items-center justify-between">
              <h1 className="text-xl font-semibold">Booking Pages</h1>
              <Button
                variant="outline"
                onClick={() => {
                  setNewBookingPage(true);
                }}
                color={"#134fb4"}
                leftIcon={<IconPlus size="1em" />}
              >
                Create
              </Button>
            </div>
            <div className="mt-2">
              <TipCycle currentPage="booking" />
            </div>
            <div className="w-full h-full flex-wrap flex gap-4 pt-8 pb-8 items-start overflow-auto">
              {bookingPages.map((bookingPage) => {
                let initials = `${bookingPage.name[0]}`;
                if (bookingPage.name.includes(" ")) {
                  let nameSplit = bookingPage.name.split(" ");
                  initials = `${nameSplit[0][0]}${nameSplit[1][0]}`;
                }
                return (
                  <div
                    className="w-[275px] cursor-pointer hover:bg-slate-50  overflow-hidden bg-white mih-[275px] miw-[275px] relative h-[275px] rounded-md border border-gray-200 shadow-md"
                    key={bookingPage.id}
                    onClick={() => handleViewClick(bookingPage.id)}
                  >
                    <div className="h-[1px] w-full bg-gray-200 absolute top-1/4 left-0" />
                    <div
                      className={`bg-white h-[75px] p-[1px] w-[75px] shadow-sm border border-gray-200 rounded-full absolute top-[32px] left-[20px]`}
                    >
                      <Avatar
                        size={"100%"}
                        radius={120}
                        mx="auto"
                        color={bookingPage.color_theme}
                        src={bookingPage?.logo_url}
                      >
                        <p className="text-2xl uppercase font-semibol">
                          {initials}
                        </p>
                      </Avatar>
                    </div>

                    <div className="h-full w-full pt-[130px] p-[20px] flex flex-col">
                      <h2 className="text-sm font-normal text-gray-400">
                        {bookingPage?.role}
                      </h2>
                      <h1 className="text-lg font-semibold mt-1">
                        {bookingPage?.name}
                      </h1>
                    </div>
                    {bookingPage?.role === "Owner" && (
                      <ActionIcon
                        className="absolute bottom-3 right-3 hover:bg-gray-100 rounded-full p-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          const confirmed = window.confirm(
                            "Are you sure you want to delete this booking page? \nThis will delete all associated data."
                          );
                          if (confirmed) {
                            deleteEnterprise(bookingPage.id);
                          }
                        }}
                      >
                        <IconTrash color={"#D0342C"} size="1.35em" />
                      </ActionIcon>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      <Modal
        title="Create Booking Page"
        opened={newBookingPage}
        onClose={() => {
          setNewBookingPage(false);
          resetFormFields();
        }}
        size={"50em"}
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <Title order={4}>Booking Page Information</Title>
        <div
          style={{
            marginTop: "1em",
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Avatar
            p={"md"}
            size="8em"
            style={{ overflow: "hidden", borderRadius: "10px" }}
            src={image}
          />
          <div style={{ width: "80%" }}>
            <TextInput
              label="Name"
              withAsterisk
              onChange={(event) => setName(event.target.value)}
            />
            <FileInput
              mt="0.5em"
              icon={<IconUpload />}
              label="Logo"
              onChange={handleImageChange}
              multiple={false}
              accept="image/jpeg, image/png, image/jpg"
            />
          </div>
        </div>
        <Flex mt="1.45em" gap={"md"}>
          <TextInput
            w={"50%"}
            label="Location"
            icon={<IconMapPin />}
            value={location}
            onChange={(event) => setLocation(event.target.value)}
          />

          <TextInput
            w={"50%"}
            label="Website"
            icon={<IconAppWindow />}
            onChange={(event) => setWebsite(event.target.value)}
          />
        </Flex>
        <Box maw={"100%"}>
          <div
            style={{
              display: "flex",
              gap: "0.15em",
              alignItems: "center",
              marginTop: "1.25em",
            }}
          >
            <Text size="sm">Theme -</Text>
            <Text italic weight={700} size="sm" color={theme}>
              {theme.charAt(0).toUpperCase() + theme.slice(1)}
            </Text>
          </div>
          <Box display="flex" justifyContent="space-between" mt="1em">
            {baseColors.map((color) => (
              <Paper
                key={color}
                style={{
                  backgroundColor: color,
                  width: "5em",
                  height: "2em",
                  cursor: "pointer",
                  marginRight: "0.25em",
                  border: theme === color ? "2px solid gray" : "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => setTheme(color)}
              >
                {theme === color && <IconCheck icon="check" color="white" />}
              </Paper>
            ))}
          </Box>
        </Box>
        <Textarea
          mt="1em"
          label="Description"
          onChange={(event) => setDescription(event.target.value)}
        />
        <Divider mt="1em" mb="1em" />
        <Title order={4}>Members</Title>
        <Text color={"rgba(0,0,0,0.5)"}>
          You'll be able to add more members later
        </Text>
        <div
          style={{
            width: "100%",
            marginTop: "1em",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
          }}
        >
          {members.map((member, index) => (
            <div
              style={{
                display: "flex",
                gap: "1em",
                alignItems: "center",
                justifyContent: "space-between",
                border: "solid 1px rgba(0,0,0,0.15)",
                borderRadius: "5px",
                padding: "0.5em 0.75em",
              }}
              key={index}
            >
              <Text truncate>{member.email}</Text>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                {member.role === "Owner" ? (
                  <Select disabled data={["Owner"]} value={"Owner"} />
                ) : (
                  <Select
                    data={["Admin", "Member"]}
                    value={member.role}
                    onChange={(role) => handleRoleChange(index, role)}
                    miw={"100px"}
                  />
                )}
                {member.role !== "Owner" && (
                  <ActionIcon onClick={() => handleRemoveMember(index)}>
                    <IconX />
                  </ActionIcon>
                )}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "1em",
            marginTop: "2em",
          }}
        >
          <TextInput
            label="Enter user's email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            w={"250px"}
            miw={"175px"}
          />
          <Button variant="outline" onClick={handleAddMember}>
            Add Member
          </Button>
        </div>
        <p className="text-red-500 font-light text-sm mb-2 mt-8">{error}</p>
        <Button
          loading={isLoading}
          variant="outline"
          onClick={handleSubmit}
          w={"100%"}
          mb="1em"
        >
          Create New Booking Page
        </Button>
      </Modal>
    </Container>
  );
}

export default Home;
