import {
  Avatar,
  Button,
  Card,
  Collapse,
  Container,
  Flex,
  Grid,
  Group,
  Paper,
  Select,
  SimpleGrid,
  Skeleton,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mui/material";
import {
  IconAt,
  IconCalendarEvent,
  IconChevronDown,
  IconChevronUp,
  IconGlobe,
  IconMail,
  IconMapPin,
  IconMessageCircle,
  IconSearch,
  IconUser,
  IconWorld,
} from "@tabler/icons-react";
import { format } from "date-fns";
import React from "react";
import { useState } from "react";
import moment from "moment";
import { convertEventTimezone, formatAMPM } from "../../Helper/TimeFormatting";
import axios from "axios";
import TipCycle from "../../TipCycle";

function Agenda({ agendaData, bookingPageData, setAgendaData }) {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [openCollapse, setOpenCollapse] = useState(null);
  const [filterOption, setFilterOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function getUserName(userId) {
    const user = bookingPageData.members.find(
      (user) => user.id === Number(userId)
    );

    return user ? user.name : "";
  }

  const deleteEvent = (id) => {
    if (
      window.confirm(
        "Are you sure you want to cancel this event? \nThe guest will be sent a notification email"
      )
    ) {
      try {
        setIsLoading(true);
        axios
          .delete(`/api/deleteEventHost/${id}`)
          .then((response) => {
            console.log(response.data);
            setOpenCollapse(null);
            setAgendaData(agendaData.filter((link) => link.id !== id));
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  let filteredAndSortedAgenda = agendaData;

  if (filterOption) {
    filteredAndSortedAgenda = filteredAndSortedAgenda.filter(
      (event) => getUserName(event.host) === filterOption
    );
  }

  if (!bookingPageData[0]) {
    return (
      <Container miw={"100%"} mih={"100%"} pl={isMobile ? "0" : "80px"}>
        <Title
          p="xs"
          style={{ borderBottom: "solid 1px rgba(0,0,0,0.15)" }}
          order={2}
        >
          Agenda
        </Title>
        <Grid mt={"sm"} w={"100%"} h={"100%"} style={{ overflow: "auto" }}>
          {agendaData.map((event, index) => {
            return (
              <Skeleton
                withBorder
                p={"1em"}
                mt={"1em"}
                ml={"1em"}
                w={"100%"}
                maw={"300px"}
                h={"300px"}
                mih={"300px"}
                mah={"300px"}
                shadow="xs"
                style={{ position: "relative" }}
                key={index}
              />
            );
          })}
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container miw={"100%"} mih={"100%"} pl={isMobile ? "0" : "80px"}>
        <Title
          p="xs"
          style={{ borderBottom: "solid 1px rgba(0,0,0,0.15)" }}
          order={2}
        >
          Agenda
        </Title>
        <Flex
          align={"flex-start"}
          mt={"sm"}
          w={"100%"}
          h={"100%"}
          style={{
            alignContent: "flex-start",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          wrap="wrap"
        >
          <Flex
            direction={isMobile ? "column" : "row"}
            gap={"md"}
            style={{ overflow: "auto" }}
            justify={"space-between"}
            align={"center"}
            mb={"sm"}
            w={"100%"}
          >
            {!isMobile && <TipCycle currentPage="agenda" />}
            <Select
              placeholder="Filter by Host"
              data={[
                { value: null, label: "No Filter" },
                ...Array.from(
                  new Set(agendaData.map((event) => getUserName(event.host)))
                ).map((name) => ({ value: name, label: name })),
              ]}
              onChange={(value) => setFilterOption(value)}
              w={isMobile ? "100%" : "15%"}
            />
          </Flex>
          {agendaData.length > 0 ? (
            filteredAndSortedAgenda.map((event, index) => {
              const convertedEvent = convertEventTimezone(
                event,
                event.timezone,
                moment.tz.guess()
              );
              const [year, month, day] = convertedEvent.date.split("-");
              const monthName = format(new Date(year, month - 1, day), "MMM");
              const dayWithSuffix = format(
                new Date(year, month - 1, day),
                "do"
              );
              const startTime = formatAMPM(convertedEvent.meeting_time_start);
              const endTime = formatAMPM(convertedEvent.meeting_time_end);
              return (
                <Paper
                  maw={"350px"}
                  miw={"275px"}
                  padding="md"
                  style={{
                    borderRadius: "20px",
                    width: "100%",
                  }}
                  key={index}
                >
                  <Card
                    withBorder
                    p={"1em"}
                    ml={isMobile ? "0em" : "1em"}
                    mt={"1em"}
                    shadow="xs"
                    style={{
                      position: "relative",
                      overflow: "visible",
                    }}
                    key={index}
                  >
                    <Group
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setOpenCollapse(index === openCollapse ? null : index)
                      }
                      noWrap
                    >
                      <Avatar
                        size={90}
                        radius="md"
                        color={`${bookingPageData[0]?.color_theme}`}
                      >
                        <Flex
                          direction={"column"}
                          justify={"center"}
                          align={"center"}
                        >
                          <Title order={3} align={"center"}>
                            {monthName}
                          </Title>
                          <Title order={4} align={"center"}>
                            {dayWithSuffix}
                          </Title>
                        </Flex>
                      </Avatar>
                      <div>
                        <Group noWrap spacing={10} mt={2}>
                          <Text fz={"sm"} fw={600}>
                            {event.event} with {event.name}
                          </Text>
                        </Group>
                        <Text fz="sm" fw={500}>
                          {startTime} - {endTime}
                        </Text>
                        <Group noWrap spacing={10} mt={3}>
                          <Text
                            italic={getUserName(event.host).length == 0}
                            c={
                              getUserName(event.host).length > 0
                                ? "dimmed"
                                : "red"
                            }
                            fz={"xs"}
                          >
                            {getUserName(event.host).length > 0
                              ? "hosted by " + getUserName(event.host)
                              : "Host has been removed"}
                          </Text>
                        </Group>
                      </div>
                    </Group>
                    <Flex direction="column">
                      <Collapse
                        in={openCollapse === index}
                        style={{ marginTop: "5px" }}
                      >
                        <Flex
                          direction="column"
                          align="flex-start"
                          style={{
                            padding: "10px",
                          }}
                        >
                          <Text
                            size="lg"
                            weight={700}
                            style={{ marginBottom: "10px" }}
                          >
                            Meeting Info
                          </Text>
                          <Flex align="center" style={{ marginBottom: "5px" }}>
                            <IconWorld
                              size={16}
                              style={{ marginRight: "5px" }}
                            />
                            <Text size="sm" fw={600}>
                              Guest Timezone:
                            </Text>
                            <Text
                              size="sm"
                              style={{ marginLeft: "5px", color: "black" }}
                            >
                              {event.timezone.replace("_", " ")}
                            </Text>
                          </Flex>
                          <Flex align="center" style={{ marginBottom: "5px" }}>
                            <IconUser
                              size={16}
                              style={{ marginRight: "5px" }}
                            />
                            <Text size="sm" fw={600}>
                              Guest Name:
                            </Text>
                            <Text size="sm" style={{ marginLeft: "5px" }}>
                              {event.name}
                            </Text>
                          </Flex>
                          <Flex align="center" style={{ marginBottom: "5px" }}>
                            <IconMail
                              size={16}
                              style={{ marginRight: "5px" }}
                            />
                            <Text size="sm" fw={600}>
                              Email:
                            </Text>
                            <Text
                              size="sm"
                              style={{ marginLeft: "5px", color: "black" }}
                            >
                              {event.email}
                            </Text>
                          </Flex>
                          <Flex align="center" style={{ marginBottom: "5px" }}>
                            <IconMapPin
                              size={16}
                              style={{ marginRight: "5px" }}
                            />
                            <Text size="sm" fw={600}>
                              Location:
                            </Text>
                            <Text size="sm" style={{ marginLeft: "5px" }}>
                              {event.location}
                            </Text>
                          </Flex>
                          <Flex align="center" style={{ marginBottom: "5px" }}>
                            <IconMessageCircle
                              size={16}
                              style={{ marginRight: "5px" }}
                            />
                            <Text
                              size="sm"
                              style={{ fontStyle: "italic", color: "gray" }}
                            >
                              Comments: {event.comments}
                            </Text>
                          </Flex>
                          <Button
                            mt={"1em"}
                            variant={"light"}
                            color={`red`}
                            onClick={() => deleteEvent(event.id)}
                            loading={isLoading}
                          >
                            Cancel Event
                          </Button>
                        </Flex>
                      </Collapse>
                    </Flex>
                  </Card>
                </Paper>
              );
            })
          ) : (
            <Flex direction={"column"} mt={"md"} gap="0.25em">
              <Title order={5}>
                There are currently no upcoming meetings scheduled.
              </Title>
              <Title order={6} color="gray">
                To arrange new meetings, please share your schedule links with
                others!
              </Title>
            </Flex>
          )}
        </Flex>
      </Container>
    );
  }
}

export default Agenda;
