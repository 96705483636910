import moment from "moment-timezone";

export const convertToUTC = (time, timezone) => {
  return moment.tz(formatTime(time), "HH:mm", timezone).utc().format("HH:mm");
};

export const convertToLocalTime = (time, timezone) => {
  return moment.utc(formatTime(time), "HH:mm").tz(timezone).format("HH:mm");
};

export const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

export function formatTimeToString(time) {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
  return formattedTime;
}

export function formatAMPM(time) {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;

  let adjustedHours = hours % 12 || 12;
  let mid = "am";
  if (hours >= 12) {
    mid = "pm";
  }
  return adjustedHours + ":" + (minutes < 10 ? "0" : "") + minutes + mid;
}

export const addMinutesToHHMM = (time, minutes) => {
  const hours = Math.floor(time / 100);
  const mins = time % 100;
  const newMins = mins + minutes;

  if (newMins >= 60) {
    return (hours + Math.floor(newMins / 60)) * 100 + (newMins % 60);
  } else {
    return hours * 100 + newMins;
  }
};

export const convetFromMiutes = (time) => {
  if (time < 60) {
    return `${time} min`;
  } else if (time >= 60 && time < 120) {
    return `${Math.floor(time / 60)} hour`;
  } else if (time >= 120) {
    return `${time % 60} hours`;
  }
};

export const timeSchedule = [];

for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 60; j += 15) {
    const value = i * 100 + j;
    const label = formatAMPM(value);
    timeSchedule.push({ value, label });
  }
}

export const formatTime = (time) => {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

export const checkSlotConflict = (day, newSlot, userAvailability) => {
  const daySlots = userAvailability[day].slots;
  for (let slot of daySlots) {
    if (newSlot.from < slot.to && newSlot.to > slot.from) {
      // There is a time conflict
      return true;
    }
  }
  // No conflicts found
  return false;
};

export const formattedTimezones = () => {
  const timezones = moment.tz.names();

  return timezones.map((zone) => {
    const parts = zone.split("/");
    let label = "";

    if (parts.length === 2) {
      label = `${parts[1].replace(/_/g, " ")}`;
    } else if (parts.length === 3) {
      label = `${parts[1].replace(/_/g, " ")} - ${parts[2].replace(/_/g, " ")}`;
    } else {
      label = zone.replace(/_/g, " ");
    }

    const nowInTimezone = moment.tz(zone).format("h:mm A");

    return {
      label: `${label} (${nowInTimezone})`,
      value: zone,
      group: parts[0],
    };
  });
};

export function timePeriodToMinutes(timePeriod) {
  if (!timePeriod || typeof timePeriod !== "string") {
    return null;
  }

  const [value, unit] = timePeriod.split(" ");

  switch (unit) {
    case "minute":
    case "minutes":
      return Number(value);
    case "hour":
    case "hours":
      return Number(value) * 60;
    case "day":
    case "days":
      return Number(value) * 60 * 24;
    case "week":
    case "weeks":
      return Number(value) * 60 * 24 * 7;
    case "month":
    case "months":
      return Number(value) * 60 * 24 * 7 * 30; // assuming a month has 30 days
    case "year":
    case "years":
      return Number(value) * 60 * 24 * 365;
    case "indefinitely":
      return Infinity;
    default:
      return NaN; // Return NaN for unrecognised units
  }
}

export function convertEventDate(date, time, fromTimezone, toTimezone) {
  const dateTime = moment.tz(
    `${date.format("YYYY-MM-DD")}T${time}`,
    fromTimezone
  );

  const convertedDateTime = dateTime.clone().tz(toTimezone);
  const newDate = convertedDateTime.format("YYYY-MM-DD");

  return newDate;
}

export function convertTimezone(time, date, fromTimezone, toTimezone) {
  // Calculate hours and minutes from time
  const hours = Math.floor(time / 100);
  const minutes = time % 100;

  // Construct date object
  const dateTime = moment.tz(
    `${date.format("YYYY-MM-DD")}T${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:00`,
    fromTimezone
  );

  // Convert timezone
  const convertedDateTime = dateTime.clone().tz(toTimezone);

  // Get converted hours and minutes
  const convertedHours = convertedDateTime.hours();
  const convertedMinutes = convertedDateTime.minutes();

  // Construct the new time as HHMM
  const newTime = convertedHours * 100 + convertedMinutes;

  return newTime;
}

function dayAfterTimezoneConversion(originalTime, convertedTime, originalDay) {
  const originalDayIndex = daysOfWeek.indexOf(originalDay);

  if (convertedTime < originalTime && originalTime - convertedTime > 300) {
    return daysOfWeek[(originalDayIndex + 1) % 7]; // Next day
  } else if (
    convertedTime > originalTime &&
    convertedTime - originalTime > 2100
  ) {
    return daysOfWeek[(originalDayIndex + 6) % 7];
  } else {
    return originalDay;
  }
}

export function convertAvailability(
  userAvailability,
  fromTimezone,
  toTimezone
) {
  const availabilityInNewTimezone = {
    sun: { isOpen: false, slots: [] },
    mon: { isOpen: false, slots: [] },
    tue: { isOpen: false, slots: [] },
    wed: { isOpen: false, slots: [] },
    thu: { isOpen: false, slots: [] },
    fri: { isOpen: false, slots: [] },
    sat: { isOpen: false, slots: [] },
  };

  if (!userAvailability) {
    console.error(
      "convertAvailability called with null or undefined userAvailability"
    );
    return;
  }

  // Define an arbitrary base date (first day of current year)
  const currentDate = new Date();
  const baseDate = currentDate.toISOString().split("T")[0];

  Object.entries(userAvailability).forEach(([day, { isOpen, slots }]) => {
    if (!isOpen) {
      return;
    }

    // Define the date for this day of the week
    const date = moment(baseDate).day(day);

    slots.forEach(({ from, to }) => {
      const fromConverted = convertTimezone(
        from,
        date,
        fromTimezone,
        toTimezone
      );
      const toConverted = convertTimezone(to, date, fromTimezone, toTimezone);

      // Determine the days for the start and end times
      const fromDay = dayAfterTimezoneConversion(from, fromConverted, day);
      const toDay = dayAfterTimezoneConversion(to, toConverted, day);

      // Check if the slot spans two days
      if (fromDay !== toDay) {
        if (
          availabilityInNewTimezone[fromDay].slots.find(
            (slot) => slot.from === fromConverted && slot.to === 2359
          ) === undefined
        ) {
          availabilityInNewTimezone[fromDay].slots.push({
            from: fromConverted,
            to: 2359,
          });
        }
        if (
          availabilityInNewTimezone[toDay].slots.find(
            (slot) => slot.from === 0 && slot.to === toConverted
          ) === undefined
        ) {
          availabilityInNewTimezone[toDay].slots.push({
            from: 0,
            to: toConverted,
          });
        }
      } else {
        // Push the slots into the new availability object
        if (
          availabilityInNewTimezone[fromDay].slots.find(
            (slot) => slot.from === fromConverted && slot.to === toConverted
          ) === undefined
        ) {
          availabilityInNewTimezone[fromDay].slots.push({
            from: fromConverted,
            to: toConverted,
          });
        }
      }

      // Set the day as open
      availabilityInNewTimezone[fromDay].isOpen = true;
      if (fromDay !== toDay) {
        availabilityInNewTimezone[toDay].isOpen = true;
      }
    });
  });

  return availabilityInNewTimezone;
}

export function convertEventTimezone(event, fromTimezone, toTimezone) {
  // Convert the meeting start time
  const convertedMeetingStartTime = convertTimezone(
    event.meeting_time_start,
    moment.tz(event.date, event.timezone),
    fromTimezone,
    toTimezone
  );

  // Convert the meeting end time
  const convertedMeetingEndTime = convertTimezone(
    event.meeting_time_end,
    moment.tz(event.date, event.timezone),
    fromTimezone,
    toTimezone
  );

  // Convert the date
  const convertedDate = convertEventDate(
    moment.tz(event.date, event.timezone),
    formatTimeToString(event.meeting_time_start),
    fromTimezone,
    toTimezone
  );

  return {
    ...event,
    date: convertedDate,
    meeting_time_start: convertedMeetingStartTime,
    meeting_time_end: convertedMeetingEndTime,
  };
}


export function convertEventsTimezone(events, toTimezone) {
  return events.map((event) =>
    convertEventTimezone(event, event.timezone, toTimezone)
  );
}
