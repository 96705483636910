import { useForm } from "@mantine/form";
import {
  Checkbox,
  Modal,
  Group,
  Flex,
  Avatar,
  Title,
  Button,
  Grid,
  Collapse,
  Select,
  ActionIcon,
  Text,
  TextInput,
  Textarea,
  Divider,
  Tooltip,
  MultiSelect,
  Badge,
  Paper,
} from "@mantine/core";
import axios from "axios";
import moment from "moment-timezone";
import {
  IconPlus,
  IconMinus,
  IconWorld,
  IconTrash,
  IconEdit,
  IconExclamationMark,
  IconExclamationCircle,
} from "@tabler/icons-react";
import {
  daysOfWeek,
  timeSchedule,
  formattedTimezones,
} from "../Helper/TimeFormatting";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { forwardRef } from "react";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar radius={"xl"} src={image} />

        <Flex direction={"column"}>
          <Text>{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </Flex>
      </Group>
    </div>
  )
);

function EditEnterprise({
  selectedTeam,
  isTeamsModalOpened,
  onClose,
  setTeamScheduleLinks,
  bookingPageData,
}) {
  // Set initial form value for selectedMember to either first user or null if error
  const [selectedMember, setSelectedMember] = useState(
    selectedTeam.members[0] || null
  );
  const [initialFormValues, setInitialFormValues] = useState({
    teamName: "",
    meetingDescription: "",
    meetingDuration: 30,
    userTimezone: moment.tz.guess(),
    userAvailability: {},
    noBookingPeriod: "",
    bookingLimit: "",
    meetingBufferTime: "",
    location: "",
    members: [],
  });

  const newTeamForm = useForm({
    initialValues: {
      teamName: selectedTeam.name,
      meetingDescription: selectedTeam.description,
      meetingDuration: selectedTeam.meeting_duration,
      userTimezone: selectedTeam.user_timezone,
      userAvailability: selectedTeam.availability,
      noBookingPeriod: selectedTeam.no_booking_period,
      bookingLimit: selectedTeam.booking_limit,
      meetingBufferTime: selectedTeam.buffer_time,
      members: selectedTeam.members,
      location: selectedTeam.location,
    },

    validate: {
      teamName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      location: (value) =>
        value.length < 2 ? "Location must have at least 2 letters" : null,
      meetingDescription: (value) =>
        value.length < 10
          ? "Description must be at least 10 characters long"
          : null,
      members: (value) =>
        value.length > 0 ? null : "Must add at least one member to team",

      userAvailability: (value) => {
        if (Object.keys(value).length === 0) {
          return "Must add at least one member to team";
        }
        let hasAvailability = false;
        for (let userId of newTeamForm.values.members) {
          let userHasAvailability = false;
          if (value[userId]) {
            for (let day in value[userId]) {
              let slots = [...value[userId][day].slots];
              slots.sort((a, b) => a.from - b.from);
              for (let i = 0; i < slots.length; i++) {
                if (slots[i].from >= slots[i].to) {
                  return "The 'start' time must be earlier than the 'end' time";
                }
                if (i > 0 && slots[i].from < slots[i - 1].to) {
                  return "Time slots must not overlap";
                }
                if (value[userId][day].isOpen && slots.length > 0) {
                  userHasAvailability = true;
                }
              }
            }
          }
          if (!userHasAvailability) {
            return `Must input availability for member ${getMemberNameById(
              userId
            )}`;
          }
          hasAvailability = true;
        }
        return hasAvailability
          ? null
          : "Must input availability for all selected members";
      },
    },
  });

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getMemberNameById = (memberId) => {
    const member = bookingPageData.members.find(
      (member) => member.id === memberId
    );
    return member ? member.name : "";
  };

  const [tags, setTags] = useState(selectedTeam.tags);
  const [input, setInput] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && input) {
      e.preventDefault();
      setTags([...tags, input]);
      setInput("");
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleMemberSelect = (memberId) => {
    setSelectedMember(memberId);

    if (!newTeamForm.values.userAvailability[memberId]) {
      newTeamForm.setFieldValue(`userAvailability.${memberId}`, {
        sun: { isOpen: false, slots: [] },
        mon: { isOpen: false, slots: [] },
        tue: { isOpen: false, slots: [] },
        wed: { isOpen: false, slots: [] },
        thu: { isOpen: false, slots: [] },
        fri: { isOpen: false, slots: [] },
        sat: { isOpen: false, slots: [] },
      });
    }
  };

  const handleCheckboxChange = (day) => {
    if (!selectedMember) return;
    const userAvailability =
      newTeamForm.values.userAvailability[selectedMember] || {};
    const daySlots = userAvailability[day] || { isOpen: false, slots: [] };
    newTeamForm.setFieldValue(`userAvailability.${selectedMember}.${day}`, {
      isOpen: !daySlots.isOpen,
      slots: daySlots.isOpen ? [] : [{ from: 900, to: 1700 }],
    });
  };

  const handleAddSlot = (day) => {
    if (!selectedMember) return;
    const userAvailability =
      newTeamForm.values.userAvailability[selectedMember] || {};
    const daySlots = userAvailability[day] || { isOpen: false, slots: [] };
    newTeamForm.setFieldValue(`userAvailability.${selectedMember}.${day}`, {
      ...daySlots,
      slots: [...daySlots.slots, { from: 900, to: 1700 }],
    });
  };

  const handleDeleteSlot = (day, index) => {
    if (!selectedMember) return;
    const userAvailability =
      newTeamForm.values.userAvailability[selectedMember] || {};
    const daySlots = userAvailability[day] || { isOpen: false, slots: [] };
    const updatedSlots = daySlots.slots.filter((_, i) => i !== index);
    newTeamForm.setFieldValue(`userAvailability.${selectedMember}.${day}`, {
      ...daySlots,
      slots: updatedSlots,
    });
  };

  const handleTimeChange = (day, index, position, value) => {
    if (!selectedMember) return;
    const userAvailability =
      newTeamForm.values.userAvailability[selectedMember] || {};
    const daySlots = userAvailability[day] || { isOpen: false, slots: [] };
    const updatedSlots = [...daySlots.slots];
    updatedSlots[index][position] = parseInt(value, 10);
    newTeamForm.setFieldValue(`userAvailability.${selectedMember}.${day}`, {
      ...daySlots,
      slots: updatedSlots,
    });
  };

  useEffect(() => {
    if (selectedTeam) {
      setInitialFormValues({
        teamName: selectedTeam.name,
        meetingDescription: selectedTeam.description,
        meetingDuration: 30,
        userTimezone: selectedTeam.user_timezone,
        userAvailability: selectedTeam.availability,
        noBookingPeriod: selectedTeam.no_booking_period,
        bookingLimit: selectedTeam.booking_limit,
        meetingBufferTime: selectedTeam.buffer_time,
      });
    }
  }, [selectedTeam]);

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    const isFormValid = newTeamForm.validate();
    console.log(isFormValid);

    try {
      if (isFormValid.hasErrors) {
        console.log("Form invalid");
        setIsLoading(false);
        setError("Please fill out all required fields");
        return;
      } else {
        let formValues = newTeamForm.values;

        // Filter out the availability of the users that are not included in the members array
        const filteredAvailability = Object.keys(formValues.userAvailability)
          .filter((userId) => formValues.members.includes(Number(userId)))
          .reduce((obj, key) => {
            obj[key] = formValues.userAvailability[key];
            return obj;
          }, {});

        formValues.userAvailability = filteredAvailability;
        formValues.tags = tags;

        axios
          .put(`/api/editTeamLink/${selectedTeam.id}`, formValues, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            withCredentials: true,
          })
          .then((response) => {
            console.log("Success", response.data);
            setTeamScheduleLinks((prevLinks) => {
              const updatedLinks = [...prevLinks];
              const index = updatedLinks.findIndex(
                (link) => link.id === selectedTeam.id
              );
              updatedLinks[index] = response.data[0];
              return updatedLinks;
            });
            setIsLoading(false);
            onClose();
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            setError(`Error: ${error.response.data.message}`);
          });
      }
    } catch (error) {
      console.log(error);
      setError(`Error: ${error.response.data.message}`);
      setIsLoading(false);
    } finally {
      setError("");
    }
  };

  return (
    <Modal
      title="Edit Service"
      opened={isTeamsModalOpened}
      // fullScreen={isMobile}
      onClose={onClose}
      size={"50em"}
      transitionProps={{ transition: "fade", duration: 200 }}
    >
      <Title className="mb-4" order={3}>
        Service Info
      </Title>
      <TextInput
        placeholder="Marketing"
        label="Service Name"
        fullWidth
        withAsterisk
        {...newTeamForm.getInputProps("teamName")}
        mt="0.25em"
        className="mb-6"
      />

      <MultiSelect
        label="Members"
        mt="0.5em"
        withAsterisk
        className="mb-6"
        itemComponent={SelectItem}
        description="Select members for this service"
        data={bookingPageData.members.map((member) => ({
          value: member.id,
          label: member.name,
          description: member.email,
          image: member.profile_image,
        }))}
        multi
        placeholder="Select members"
        {...newTeamForm.getInputProps("members")}
      />

      <div className="mb-6">
        <TextInput
          placeholder="Type a tag and press enter"
          value={input}
          onChange={(event) => setInput(event.currentTarget.value)}
          onKeyDown={handleKeyDown}
          label={"Tags"}
          mt={"0.75em"}
          description={
            "Enter tags associated with this service or click on tags to remove them"
          }
        />
        <Paper padding="md" style={{ marginTop: "0.65em" }}>
          {tags.map((tag) => (
            <Badge
              color={`${bookingPageData[0].color_theme}`}
              key={tag}
              onClick={() => removeTag(tag)}
              style={{
                marginRight: "0.5em",
                marginBottom: "0.5em",
                cursor: "pointer",
              }}
            >
              {tag}
            </Badge>
          ))}
        </Paper>
      </div>

      <Textarea
        placeholder="Include a description of your service or any details your attendees should know"
        mt="0.75em"
        label="Meeting Description"
        fullWidth
        withAsterisk
        className="mb-6"
        {...newTeamForm.getInputProps("meetingDescription")}
      />

      <TextInput
        placeholder="BlueMail Meet"
        label="Location"
        fullWidth
        withAsterisk
        className="mb-6"
        {...newTeamForm.getInputProps("location")}
        mt="0.75em"
      />

      <Divider mt="1em" mb="0.5em" className="mb-4 mt-8" />
      <div className="flex justify-between items-end mb-4">
        <div className="Availability-Title">
          <Title order={3}>Service Availability</Title>
          <Title className="font-light mt-1" color="rgba(0,0,0,0.5)" order={6}>
            Input weekly general availability for each member
          </Title>
          {newTeamForm.errors.userAvailability && (
            <Flex align={"center"} justify={"flex-start"} gap={"md"}>
              <IconExclamationCircle color={`red`} size={"1em"} />
              <Text mt={"1em"} mb={"1em"} color={`red`} italic size="sm">
                {newTeamForm.errors.userAvailability}
              </Text>
            </Flex>
          )}
        </div>
        <Select
          variant="unstyled"
          placeholder="Start typing to see options"
          data={formattedTimezones()}
          searchable
          value={newTeamForm.values.userTimezone}
          {...newTeamForm.getInputProps("userTimezone")}
          groupBy={(item) => item.group}
          icon={<IconWorld color="black" size="1.25em" />}
          mt="0.25em"
          mb="0.25em"
          ml="auto"
        />
      </div>
      <Select
        variant="unstyled"
        placeholder="Select a member"
        className="border mt-2 mb-4 border-gray-400 rounded-md px-2"
        data={newTeamForm.values.members.map((memberId) => ({
          label: getMemberNameById(memberId),
          value: memberId,
        }))}
        defaultValue={newTeamForm.values.members[0]}
        value={selectedMember}
        onChange={handleMemberSelect}
      />

      {selectedMember ? (
        <div>
          <h2>{getMemberNameById(selectedMember)}'s Availability</h2>
          <div className="Availability-Grid">
            {daysOfWeek.map((day) => (
              <div className="Availability-Item">
                <Checkbox
                  color={`${bookingPageData[0].color_theme}`}
                  checked={
                    newTeamForm.values.userAvailability[selectedMember] &&
                    newTeamForm.values.userAvailability[selectedMember][day] &&
                    newTeamForm.values.userAvailability[selectedMember][day]
                      .slots.length > 0
                  }
                  label={day.charAt(0).toUpperCase() + day.slice(1)}
                  onChange={() => handleCheckboxChange(day)}
                />
                <Collapse
                  in={
                    newTeamForm.values.userAvailability[selectedMember] &&
                    newTeamForm.values.userAvailability[selectedMember][day] &&
                    newTeamForm.values.userAvailability[selectedMember][day]
                      .isOpen
                  }
                >
                  {newTeamForm.values.userAvailability[selectedMember] &&
                  newTeamForm.values.userAvailability[selectedMember][day]
                    ? newTeamForm.values.userAvailability[selectedMember][
                        day
                      ].slots.map((slot, index) => (
                        <div className="Availability-Inputs">
                          <Select
                            data={timeSchedule}
                            value={slot.from}
                            placeholder="From"
                            onChange={(value) =>
                              handleTimeChange(day, index, "from", value)
                            }
                            width="auto"
                          />
                          <Text> - </Text>
                          <Select
                            data={timeSchedule}
                            value={slot.to}
                            placeholder="To"
                            onChange={(value) =>
                              handleTimeChange(day, index, "to", value)
                            }
                            width="auto"
                          />

                          <ActionIcon
                            onClick={() => handleDeleteSlot(day, index)}
                          >
                            <IconMinus />
                          </ActionIcon>
                        </div>
                      ))
                    : null}
                  <Button
                    onClick={() => handleAddSlot(day)}
                    leftIcon={<IconPlus size="2em" />}
                    variant="unstyled"
                    size="75%"
                    mt="2em"
                  >
                    Add Time Interval
                  </Button>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Text className="mb-6 mt-6 font-medium text-sm underline underline-offset-8 text-red-500">
          Select members for this service above, and then select each member to
          implement availability.
        </Text>
      )}

      <Divider mt="1em" mb="0.5em" />
      <Title className="mt-4" order={3}>
        Advanced Booking Options
      </Title>

      <div className="flex flex-col gap-6 mt-4 mb-2">
        <Select
          label="Meeting Duration"
          description="How long should attendees be able to schedule a meeting?"
          data={[
            { value: 15, label: "15 minutes" },
            { value: 30, label: "30 minutes" },
            { value: 60, label: "1 hour" },
          ]}
          placeholder="Choose a value"
          {...newTeamForm.getInputProps("meetingDuration")}
        />
        <Select
          clearable
          label="Buffer Time Between Meetings"
          description="How long should the gap be between meetings?"
          data={[
            { value: 5, label: "5 minutes" },
            { value: 15, label: "15 minutes" },
            { value: 30, label: "30 minutes" },
            { value: 60, label: "1 hour" },
          ]}
          placeholder="Choose a value"
          {...newTeamForm.getInputProps("meetingBufferTime")}
        />
        <Select
          clearable
          label="Earliest Booking Time"
          description="How many days from today can users start booking meetings?"
          data={[
            { value: 1440, label: "1 day" },
            { value: 2880, label: "2 days" },
            { value: 4320, label: "3 days" },
            { value: 10080, label: "1 week" },
          ]}
          placeholder="Choose a value"
          {...newTeamForm.getInputProps("noBookingPeriod")}
        />
        <Select
          clearable
          label="Latest Booking Time"
          description="How far into the future can users book meetings?"
          data={[
            { value: 10080, label: "1 week" },
            { value: 20160, label: "2 weeks" },
            { value: 40320, label: "1 month" },
            { value: 241920, label: "6 months" },
            { value: 483840, label: "1 year" },
          ]}
          placeholder="Choose a value"
          {...newTeamForm.getInputProps("bookingLimit")}
        />
      </div>
      <div className="mt-6 flex flex-col">
        <p className="text-sm text-red-500 mb-2">{error}</p>

        <Button
          onClick={(event) => {
            handleSubmit(event);
          }}
          loading={isLoading}
          mb="1em"
          variant="outline"
        >
          Save Changes
        </Button>
      </div>
    </Modal>
  );
}

export default EditEnterprise;
