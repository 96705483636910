import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { IconCheck, IconChevronDown, IconChevronUp } from "@tabler/icons-react";

function createTimeSlotString(from, to, timezone) {
  const formatString = "h:mm A";
  return `${from.tz(timezone).format(formatString)} - ${to
    .tz(timezone)
    .format(formatString)} (${from.tz(timezone).format("z")})`;
}

function getAvailabilityForNextTwoWeeks(
  availability,
  timezone,
  meetingDuration
) {
  const today = moment();
  const twoWeeksFromNow = moment().add(14, "days");
  let schedule = [];

  // Generate schedule for the next two weeks
  for (let m = moment(today); m.isBefore(twoWeeksFromNow); m.add(1, "days")) {
    const dayOfWeek = m.format("ddd").toLowerCase();
    const dayAvailability = availability[dayOfWeek];

    if (dayAvailability && dayAvailability.isOpen) {
      dayAvailability.slots.forEach((slot) => {
        const from = m
          .clone()
          .hour(Math.floor(slot.from / 100))
          .minute(slot.from % 100);
        const to = m
          .clone()
          .hour(Math.floor(slot.to / 100))
          .minute(slot.to % 100);
        schedule.push({
          date: m.format("dddd, MMMM Do"),
          slot: createTimeSlotString(from, to, timezone),
        });
      });
    }
  }

  console.log(schedule);

  return schedule;
}

const ClientIntegration = () => {
  const [services, setServices] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [activeService, setActiveService] = useState(null);
  const userId = 3;

  useEffect(() => {
    axios
      .get(`http://localhost:5001/client/${userId}/services`)
      .then((response) => {
        // Initialize an 'opened' state for each service
        let servicesWithState = response.data[0].map((service) => ({
          ...service,
          opened: false,
        }));
        console.log("Services", servicesWithState);
        setServices(servicesWithState);
      })
      .catch((error) => console.error("Error fetching services", error));
  }, [userId]);

  const toggle = (id) => {
    setServices(
      services.map((service) => {
        if (service.id === id) {
          return { ...service, opened: !service.opened };
        }
        return service;
      })
    );
  };

  const handleSlotChange = (serviceId, slot) => {
    console.log("Slot", selectedSlots);
    const isSlotSelected = selectedSlots.some(
      (s) => s.date === slot.date && s.slot === slot.slot
    );

    if (activeService !== serviceId) {
      setSelectedSlots([slot]);
      setActiveService(serviceId);
    } else {
      setSelectedSlots((currentSlots) => {
        if (isSlotSelected) {
          return currentSlots.filter(
            (s) => s.date !== slot.date || s.slot !== slot.slot
          );
        } else {
          return [...currentSlots, slot];
        }
      });
    }
  };

  const handleBookingClick = () => {
    const bookingInfo = selectedSlots.map((slot) => {
      const serviceInfo = services.find(
        (service) => service.id === activeService
      );
      return {
        slot,
        service: {
          id: serviceInfo.id,
          name: serviceInfo.name,
          enterpriseName: serviceInfo.enterprise_name,
          meetingDuration: serviceInfo.meeting_duration,
        },
      };
    });
    console.log("Booking Info:", bookingInfo);
  };

  return (
    <div
      style={{
        width: "350px",
        height: "100%",
        backgroundColor: "#1e1e1e",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h3 style={{ color: "#fff" }}>Select Availability</h3>
      <h5 style={{ color: "#9C9C9C" }}>Choose from available services</h5>
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#333",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      />
      <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
        {services.map(
          (service) =>
            service.availability[service.member_id] && (
              <div
                key={service.id}
                style={{
                  marginTop: "0.65rem",
                  backgroundColor: "#1A1A1A",
                  borderRadius: "5px",
                  border: "1px solid #333333",
                  padding: "1rem",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <h5 style={{ color: "#fff" }}>{service.name}</h5>
                      <p style={{ color: "#9C9C9C", fontSize: "small" }}>
                        {service.enterprise_name} • {service.meeting_duration}{" "}
                        min
                      </p>
                    </div>
                    <button
                      onClick={() => toggle(service.id)}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#fff",
                      }}
                    >
                      {service.opened ? (
                        <IconChevronUp size={16} />
                      ) : (
                        <IconChevronDown size={16} />
                      )}
                    </button>
                  </div>
                </div>
                {service.opened && (
                  <div style={{ marginTop: "1rem" }}>
                    {service?.availability &&
                      service.availability[service?.member_id] &&
                      getAvailabilityForNextTwoWeeks(
                        service.availability[service?.member_id],
                        service.user_timezone,
                        service.meeting_duration
                      ).map((slot, index) => (
                        <label
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "8px",
                            padding: "8px",
                            borderRadius: "4px",
                            backgroundColor: selectedSlots.some(
                              (s) =>
                                s.date === slot.date && s.slot === slot.slot
                            )
                              ? "#333"
                              : "",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={
                              activeService === service.id &&
                              selectedSlots.some(
                                (s) =>
                                  s.date === slot.date && s.slot === slot.slot
                              )
                            }
                            onChange={() => handleSlotChange(service.id, slot)}
                            style={{
                              display: "none",
                            }}
                          />
                          <span
                            style={{
                              display: "inline-block",
                              width: "16px",
                              height: "16px",
                              marginRight: "10px",
                              marginLeft: "5px",
                              borderRadius: "3px",
                              backgroundColor:
                                activeService === service.id &&
                                selectedSlots.some(
                                  (s) =>
                                    s.date === slot.date && s.slot === slot.slot
                                )
                                  ? "#3B88CC"
                                  : "#555",
                              transition: "background-color 0.3s",
                              position: "relative",
                            }}
                          >
                            {activeService === service.id &&
                              selectedSlots.some(
                                (s) =>
                                  s.date === slot.date && s.slot === slot.slot
                              ) && (
                                <IconCheck
                                  size={16}
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    color: "white",
                                  }}
                                />
                              )}
                          </span>
                          <div style={{ marginLeft: "0.5rem" }}>
                            <p
                              style={{
                                color: "#fff",
                                margin: 0,
                                fontSize: "0.8rem",
                              }}
                            >
                              {slot.date}
                            </p>
                            <p
                              style={{
                                color: "#9C9C9C",
                                fontSize: "0.7rem",
                                margin: 0,
                              }}
                            >
                              {slot.slot}
                            </p>
                          </div>
                        </label>
                      ))}
                  </div>
                )}
              </div>
            )
        )}
      </div>
      {selectedSlots.length > 0 && (
        <button
          onClick={handleBookingClick}
          style={{
            marginTop: "1rem",
            padding: "10px 20px",
            fontSize: "1rem",
            color: "#fff",
            backgroundColor: "#3B88CC",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "opacity 0.3s",
          }}
        >
          Book Selected Slots
        </button>
      )}
    </div>
  );
};

export default ClientIntegration;
