import {
  ActionIcon,
  Avatar,
  Center,
  Collapse,
  Container,
  Flex,
  Grid,
  Image,
  Navbar,
  Slider,
  Stack,
  Title,
  Tooltip,
  Transition,
} from "@mantine/core";
import {
  IconBuilding,
  IconBusinessplan,
  IconCalendar,
  IconDoorExit,
  IconHome,
  IconHome2,
  IconInfoCircle,
  IconTools,
  IconUserCheck,
  IconUsersGroup,
} from "@tabler/icons-react";
import React, { useState, useEffect } from "react";
import { Watch } from "react-loader-spinner";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import Agenda from "./Tabs/Agenda";
import Teams from "./Tabs/Teams";
import axios from "axios";
import "../../Styles/EnterpriseDashboard.css";
import { useContext } from "react";
import NoData from "../../Images/no_data.svg";
import { AuthContext } from "../AuthContext";
import Services from "./Tabs/Services";
import BookingTab from "./Tabs/BookingTab";
import Customers from "./Tabs/Customers";
import { Slide, useMediaQuery } from "@mui/material";
import BusinessInfo from "./Tabs/BusinessInfo";
import moment from "moment";

function BookingPage() {
  const [currentTab, setCurrentTab] = useState("Agenda");
  const [bookingPageData, setBookingPageData] = useState([]);
  const [teamScheduleLinks, setTeamScheduleLinks] = useState([]);
  const [agendaData, setAgendaData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [isIssue, setIsIssue] = useState(false);
  const [isNavbarVisible, setNavbarVisible] = useState(false);
  const [initials, setInitials] = useState("");

  const { id } = useParams();
  const isMobile = useMediaQuery("(max-width: 50em)");
  const navigate = useNavigate();

  const tabs = [
    { icon: <IconCalendar />, label: "Agenda" },
    { icon: <IconUsersGroup />, label: "Team" },
    { icon: <IconTools />, label: "Services" },
    { icon: <IconUserCheck />, label: "Customers" },
    { icon: <IconBuilding />, label: "Business Info" },
  ];

  const renderTab = () => {
    switch (currentTab) {
      case "Home":
        return <BookingTab bookingPageData={bookingPageData} />;
      case "Agenda":
        return (
          <Agenda
            bookingPageData={bookingPageData}
            agendaData={agendaData}
            setAgendaData={setAgendaData}
          />
        );
      case "Team":
        return (
          <Teams
            bookingPageData={bookingPageData}
            setBookingPageData={setBookingPageData}
            setTeamScheduleLinks={setTeamScheduleLinks}
            teamScheduleLinks={teamScheduleLinks}
          />
        );
      case "Services":
        return (
          <Services
            setTeamScheduleLinks={setTeamScheduleLinks}
            teamScheduleLinks={teamScheduleLinks}
            bookingPageData={bookingPageData}
          />
        );
      case "Customers":
        return (
          <Customers
            bookingPageData={bookingPageData}
            agendaData={agendaData}
          />
        );
      case "Business Info":
        return (
          <BusinessInfo
            setBookingPageData={setBookingPageData}
            bookingPageData={bookingPageData}
            teamScheduleLinks={teamScheduleLinks}
          />
        );
      case "Info":
        return <Title>Info</Title>;
      default:
        return <Title>Booking Page</Title>;
    }
  };

  useEffect(() => {
    // Set loading to true at the beginning of the effect to ensure it reflects
    // the start of data fetching.
    setIsLoading(true);

    // Use Promise.all to fetch all required data concurrently.
    Promise.all([
      axios.get(`/api/getEnterprise/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        withCredentials: true,
      }),
      axios.get(`/api/getAllEnterpriseEvents/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        withCredentials: true,
      }),
      axios.get(`/api/getTeamLinks/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        withCredentials: true,
      }),
    ])
      .then(([enterpriseRes, eventsRes, teamLinksRes]) => {
        // Process and set the data for enterprise.
        const enterpriseData = enterpriseRes.data;
        setBookingPageData(enterpriseData);
        if (enterpriseData[0]?.name.includes(" ")) {
          const nameSplit = enterpriseData[0].name.split(" ");
          setInitials(`${nameSplit[0][0]}${nameSplit[1][0]}`);
        } else {
          setInitials(enterpriseData[0]?.name[0]);
        }

        // Process and set the data for events.
        const currentDate = moment().startOf("day");
        const filteredAndSortedAgenda = eventsRes.data[0]
          .filter((event) => moment(event.date).isSameOrAfter(currentDate))
          .sort(
            (a, b) =>
              moment(a.date).diff(moment(b.date)) ||
              a.meeting_time_start - b.meeting_time_start
          );
        setAgendaData(filteredAndSortedAgenda);

        // Set the data for team schedule links.
        setTeamScheduleLinks(teamLinksRes.data);

        // After all data is fetched, set loading to false.
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsIssue(true);
        setIsLoading(false);
      });
  }, [id, isLoggedIn]);

  if (isLoading) {
    return (
      <div className="MeetingLoading">
        <Watch color="#134fb4" height={125} width={125} timeout={5000} />
      </div>
    );
  } else if (isIssue) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "2em",
          textAlign: "center",
        }}
      >
        <Image maw={300} src={NoData} />
        <Title order={2}>Meeting Doesn't Exist</Title>
      </div>
    );
  } else {
    return (
      <Container miw={"100vw"} w={"100vw"} mah={"100vh"} h={"100vh"} p={"0"}>
        <HeaderMenu
          setNavbarVisible={setNavbarVisible}
          isNavbarVisible={isNavbarVisible}
        />
        <Flex
          h={"100%"}
          w={"98%"}
          style={{ position: "relative", zIndex: "100" }}
        >
          {!isMobile && (
            <Flex
              direction={"column"}
              h={"100%"}
              w={80}
              style={{ position: "fixed", left: 0, zIndex: 5 }}
              p="md"
              align={"center"}
            >
              <Flex mt={60}>
                <Tooltip
                  label={bookingPageData[0] ? bookingPageData[0].name : ""}
                  position="right"
                  transitionProps={{ duration: 0 }}
                  key={bookingPageData[0] ? bookingPageData[0].name : ""}
                >
                  <Avatar
                    style={{ border: "solid 0.25px rgba(0,0,0,0.5)" }}
                    size={"lg"}
                    color={"blue"}
                    src={bookingPageData[0] ? bookingPageData.logo_url : ""}
                  >
                    {initials}
                  </Avatar>
                </Tooltip>
              </Flex>
              <Flex
                direction={"column"}
                w={"100%"}
                h={"100%"}
                pt={20}
                justify={"space-between"}
                align={"center"}
              >
                <Flex
                  align={"center"}
                  justify={"center"}
                  direction="column"
                  w={"100%"}
                >
                  {tabs.map((tab) => (
                    <Tooltip
                      label={tab.label}
                      position="right"
                      transitionProps={{ duration: 0 }}
                      key={tab.label}
                    >
                      <ActionIcon
                        size={"xl"}
                        mt={5}
                        onClick={() => setCurrentTab(tab.label)}
                        color={`blue`}
                        variant={currentTab === tab.label && "outline"}
                      >
                        {tab.icon}
                      </ActionIcon>
                    </Tooltip>
                  ))}
                </Flex>
                <Flex w={"100%"} align={"center"} justify={"center"}>
                  <Tooltip
                    label={"Return"}
                    position="right"
                    transitionProps={{ duration: 0 }}
                    key={"Return"}
                  >
                    <ActionIcon
                      size={"xl"}
                      mt={5}
                      onClick={() => navigate("/")}
                      color={"dark"}
                    >
                      <IconDoorExit style={{ transform: "scaleX(-1)" }} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              </Flex>
            </Flex>
          )}
          {isMobile && (
            <Collapse in={isNavbarVisible} duration={400}>
              <Flex
                direction={"column"}
                h={"100%"}
                bg={"white"}
                w={80}
                style={{
                  position: "fixed",
                  left: 0,
                  zIndex: 5,
                  borderRight: "1px solid rgba(0,0,0,0.1)",
                }}
                p="md"
                align={"center"}
              >
                <Flex mt={60}>
                  <Tooltip
                    label={bookingPageData[0] ? bookingPageData[0].name : ""}
                    position="right"
                    transitionProps={{ duration: 0 }}
                    key={bookingPageData[0] ? bookingPageData[0].name : ""}
                  >
                    <Avatar
                      style={{ border: "solid 0.25px rgba(0,0,0,0.5)" }}
                      size={"lg"}
                      color={"blue"}
                      src={bookingPageData[0] ? bookingPageData.logo_url : ""}
                    >
                      {initials}
                    </Avatar>
                  </Tooltip>
                </Flex>
                <Flex
                  direction={"column"}
                  w={"100%"}
                  h={"100%"}
                  pt={20}
                  justify={"space-between"}
                  align={"center"}
                >
                  <Flex
                    align={"center"}
                    justify={"center"}
                    direction="column"
                    w={"100%"}
                  >
                    {tabs.map((tab) => (
                      <Tooltip
                        label={tab.label}
                        position="right"
                        transitionProps={{ duration: 0 }}
                        key={tab.label}
                      >
                        <ActionIcon
                          size={"xl"}
                          mt={5}
                          onClick={() => setCurrentTab(tab.label)}
                          color={`blue`}
                          variant={currentTab === tab.label && "outline"}
                        >
                          {tab.icon}
                        </ActionIcon>
                      </Tooltip>
                    ))}
                  </Flex>
                  <Flex w={"100%"} align={"center"} justify={"center"}>
                    <Tooltip
                      label={"Return"}
                      position="right"
                      transitionProps={{ duration: 0 }}
                      key={"Return"}
                    >
                      <ActionIcon
                        size={"xl"}
                        mt={5}
                        onClick={() => navigate("/")}
                        color={"dark"}
                      >
                        <IconDoorExit style={{ transform: "scaleX(-1)" }} />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                </Flex>
              </Flex>
            </Collapse>
          )}
          <Grid w={"100%"} h={"100%"} p={"5em 2em"}>
            <Flex w={"100%"} h={"100%"}>
              {renderTab()}
            </Flex>
          </Grid>
        </Flex>
      </Container>
    );
  }
}

export default BookingPage;
